import React, { Fragment, useState, useEffect, Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Charts from './home-component';
const primary = localStorage.getItem('primary_color') || "#4466f2";
const secondary = localStorage.getItem('secondary_color') || "#1ea6ec";


const Registration = () => {
    const [preregistros, setPreregistros] = useState(0)
    const [pendientes, setPendientes] = useState(0)
    const [pagados, setPagados] = useState(0)
    const [montoTotal, setMontoTotal] = useState(0)
    const [montoTotalNeto, setMontoTotalNeto] = useState(0)
    const [cortesias, setCortesias] = useState(0)
    const [pagadosCortesia, setPagadosCortesia] = useState(0)
    const [pagadosHombres, setPagadosHombres] = useState(0)
    const [pagadosMujeres, setPagadosMujeres] = useState(0)
    const [tablaEstados, setTablaEstados] = useState([])
    const [datosGrafica, setDatosGrafica] = useState([])
    const [productos, setProductos] = useState([])
    const [hoteles, setHoteles] = useState({})
    // const [palacioSencillo, setPalacioSencillo] = useState(0)
    // const [palacioDoble, setPalacioDoble] = useState(0)
    // const [palacioPendiente, setPalacioPendiente] = useState(0)
    // const [princessSencillo, setPrincessSencillo] = useState(0)
    // const [princessDoble, setPrincessDoble] = useState(0)
    // const [princessPendiente, setPrincessPendiente] = useState(0)

    const getData = async () => {
        let response = await fetch('/rt_registration', {
            method: 'POST',
            body: JSON.stringify({ evento: 4, producto: 1, dias: 30 }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            console.log('res registration', res);
            setPreregistros(res.preregistros)
            setPendientes(res.pendientes)
            setPagados(res.pagados)
            setMontoTotal(res.montoTotal)
            setMontoTotalNeto(res.montoTotalNeto)
            setCortesias(res.cortesias)
            setPagadosCortesia(res.pagadosCortesia)
            setPagadosHombres(res.pagadosHombres)
            setPagadosMujeres(res.pagadosMujeres)
            setTablaEstados(res.tablaEstados)
            setDatosGrafica(res.grafica)
            setProductos(res.productos)
            setHoteles(res.hoteles)
            // setPalacioSencillo(res.palacioSencillo)
            // setPalacioDoble(res.palacioDoble)
            // setPalacioPendiente(res.palacioPendiente)
            // setPrincessSencillo(res.princessSencillo)
            // setPrincessDoble(res.princessDoble)
            // setPrincessPendiente(res.princessPendiente)
        }
        catch {

        }
    }
    useEffect(() => {
        //getData();
    }, []);

    return (
        <Fragment>
            <Charts
                preregistros={preregistros}
                pendientes={pendientes}
                pagados={pagados}
                montoTotal={montoTotal}
                montoTotalNeto={montoTotalNeto}
                cortesias={cortesias}
                pagadosCortesia={pagadosCortesia}
                pagadosHombres={pagadosHombres}
                pagadosMujeres={pagadosMujeres}
                tablaEstados={tablaEstados}
                grafica={datosGrafica}
                productos={productos}
                hoteles={hoteles}
            // palacioSencillo={palacioSencillo}
            // palacioDoble={palacioDoble}
            // palacioPendiente={palacioPendiente}
            // princessSencillo={princessSencillo}
            // princessDoble={princessDoble}
            // princessPendiente={princessPendiente}
            />
        </Fragment>
    );
}

export default Registration;