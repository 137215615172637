import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Tooltip } from "reactstrap";
import { Fragment } from "react";
import "../../assets/css/asientos.css";
import Loader from "../common/loaderapp";
import $ from "jquery";
import ReactTooltip from "react-tooltip";

const AsientosClausura = ({ pag_actualizar, setPag_actualizar }) => {
    const navigate = useNavigate();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [is_load, setIs_load] = useState(true);
    const [sillas, setSillas] = useState({});
    const [cambia, setCambia] = useState(false);
    const [asisteneSillas, setAsisteneSillas] = useState([])
    const [listAsistentes, setListAsistentes] = useState([])
    const [es_bienvenida, setEs_bienvenida] = useState(false);

    var asistentes_ocupados_temporal = []
    var clave_buscador_asistentes = ""

    useEffect(() => {
        console.log('cambia');
        ReactTooltip.rebuild();
    }, [cambia]);

    useEffect(() => {
        setCambia(x => true)
        setSillas(x => [])
        const obtener_asientos = async () => {
            await fetch("/rt_mesas_sillas", {
                method: "POST",
                body: JSON.stringify({
                    id_cl: 4,
                }),
                headers: { "Content-Type": "application/json" },
            })
                .then((res) => res.json())
                .then((res) => {
                    console.log(res);

                    var mesas = res.datos[0]
                    var asistentes = res.datos[1]
                    setAsisteneSillas(asistentes)

                    for (let index = 0; index < mesas.length; index++) {
                        const element = mesas[index];
                        let identificador_sillas = JSON.parse(element.OBJETO_IDENTIFICADOR_SILLAS_MC)
                        let silla = []
                        var mesa_class = document.querySelector(`.mesa_${element.IDENTIFICADOR_MESA_MC}`)

                        for (let y = 0; y < identificador_sillas.length; y++) {
                            const elemento = identificador_sillas[y];

                            if (elemento.es_ocupado == 0 && !element.ES_RESERVADO) {
                                silla.push(
                                    <Fragment key={`${element.IDENTIFICADOR_MESA_MC}${y + 1}`}>
                                        <div className={`circle deg-${y + 1}`}>
                                            <span className="checkbox active">
                                                <input className="checkbox-element" type="checkbox" s={elemento.identificador} mc={element.ID_MC} m={element.IDENTIFICADOR_MESA_MC} disabled />
                                                <i className="fa-solid fa-user"></i>
                                            </span>
                                        </div>
                                    </Fragment>
                                )
                            }
                            else if (element.ES_RESERVADO) {
                                silla.push(
                                    <Fragment key={`${element.IDENTIFICADOR_MESA_MC}${y + 1}`}>
                                        <div className={`circle deg-${y + 1} `} data-tip="" data-for={`${element.IDENTIFICADOR_MESA_MC}${y + 1}`}>
                                            <span className="checkbox reservado">
                                                <input className="checkbox-element" type="checkbox" data-for="11" disabled />
                                                <i className="fa-solid fa-user"></i>
                                            </span>
                                        </div>
                                        <ReactTooltip id={`${element.IDENTIFICADOR_MESA_MC}${y + 1}`} place="top" effect="solid" wrapper="div">{element.COMENTARIOS_MC}</ReactTooltip>
                                    </Fragment>
                                )
                            }
                            else {
                                console.log(element.IDENTIFICADOR_MESA_MC, element.ID_MC, elemento.identificador);
                                var nombre_asistente = asistentes.find(x => x.ID_MC_RSC == element.ID_MC && x.IDENTIFICADOR_SILLA_RSC == elemento.identificador)
                                //console.log(nombre_asistente);
                                try {
                                    silla.push(
                                        <Fragment key={`${element.IDENTIFICADOR_MESA_MC}${y + 1}`}>
                                            <div className={`circle deg-${y + 1}`} data-tip="" style={{ zIndex: 0 }} data-for={`my-tooltip-${element.IDENTIFICADOR_MESA_MC}-${nombre_asistente.IDENTIFICADOR_SILLA_RSC}`}>
                                                <span className="checkbox ocupado">
                                                    <input className="checkbox-element" type="checkbox" disabled />
                                                    <i className="fa-solid fa-user"></i>
                                                    <ReactTooltip styl id={`my-tooltip-${element.IDENTIFICADOR_MESA_MC}-${nombre_asistente.IDENTIFICADOR_SILLA_RSC}`} place="top" effect="solid"><strong>#{nombre_asistente.FOLIO}</strong><br></br>Asiento: {nombre_asistente.IDENTIFICADOR_SILLA_RSC}<br ></br>{nombre_asistente.NOMBRE}</ReactTooltip>
                                                </span>
                                            </div>
                                        </Fragment>
                                    )
                                } catch (error) {
                                    silla.push(
                                        <Fragment key={`${element.IDENTIFICADOR_MESA_MC}${y + 1}`}>
                                            <div className={`circle deg-${y + 1}`} data-tip="" data-for={`${element.IDENTIFICADOR_MESA_MC}`}>
                                                <span className="checkbox ocupado">
                                                    <input className="checkbox-element" type="checkbox" disabled />
                                                    <i className="fa-solid fa-user"></i>
                                                </span>
                                            </div>
                                            <ReactTooltip id={`${element.IDENTIFICADOR_MESA_MC}`} place="top" effect="solid"><strong>Reservado</strong><br></br>Asiento: {elemento.identificador}</ReactTooltip>
                                        </Fragment>
                                    )
                                }

                            }
                        }

                        try {
                            if (identificador_sillas.length == 8) {
                                mesa_class.classList.add("ocho");
                            }
                            if (identificador_sillas.length == 9) {
                                mesa_class.classList.add("nueve");
                            }
                            if (identificador_sillas.length == 10) {
                                mesa_class.classList.add("diez");
                            }
                            if (identificador_sillas.length == 11) {
                                mesa_class.classList.add("once");
                            }
                            if (identificador_sillas.length == 12) {
                                mesa_class.classList.add("doce");
                            }
                        } catch (error) {
                            console.log(error);
                        }
                        //mesa_class.innerHTML = silla
                        setSillas(sillas => ({ ...sillas, [element.IDENTIFICADOR_MESA_MC]: silla }))

                    }

                    console.log('TERMINE');
                    setCambia(false)
                });
        }

        obtener_asientos()
    }, [es_bienvenida]);

    function pintar_lista_asistentes() {
        var lista = []
        var listado_txt_ocupados = ''
        var lista_asignados = document.getElementById('lista_asignados')

        console.log(asistentes_ocupados_temporal)
        for (let index = 0; index < asistentes_ocupados_temporal.length; index++) {
            const element = asistentes_ocupados_temporal[index];
            listado_txt_ocupados += `<li style="position: static;"><b>FOLIO: ${element.FOLIO} MESA: ${element.IDENTIFICADOR_MESA_MC} SILLA: ${element.IDENTIFICADOR_SILLA_RSC}</b> &nbsp;&nbsp; ${element.NOMBRE}</li>`
        }
        //console.log(listado_txt_ocupados);
        lista_asignados.innerHTML = listado_txt_ocupados
    }

    const inputBusqueda = (event) => {
        console.log('entreeee', event.target.value);
        clave_buscador_asistentes = event.target.value
        if (event.target.value == '') {
            console.log('está vacio');



            const filterItems = (needle, heystack) => {
                let query = needle.toString().toLowerCase();
                return heystack.filter(item => item.NOMBRE.toLowerCase().indexOf(query) >= 0 || item.FOLIO.toString().indexOf(query) >= 0);
            }
            asistentes_ocupados_temporal = filterItems(event.target.value, asisteneSillas);
            document.getElementById('lista_asignados').setAttribute('style', 'display: none;')
        }
        else {
            const filterItems = (needle, heystack) => {
                let query = needle.toString().toLowerCase();
                return heystack.filter(item => item.NOMBRE.toLowerCase().indexOf(query) >= 0 || item.FOLIO.toString().indexOf(query) >= 0);
            }

            asistentes_ocupados_temporal = filterItems(event.target.value, asisteneSillas);
            // btn_limpiar_asistentes.removeAttribute('style')
            document.getElementById('lista_asignados').setAttribute('style', 'display: block;')
        }

        pintar_lista_asistentes()
    }

    return (
        <div>
            <Fragment>
                {is_load && <div className="main">
                    <div className="container-fluid">
                        <div className="row titulos_asientos">
                            {/* <div className="col-6 text-center p-4">
                                <button type="button" className="cena1_cont btn" onClick={() => { navigate('/asientos') }}>
                                    <p>Mesas y asientos de:</p>
                                    <h3>Cena de bienvenida</h3>
                                </button>
                            </div> */}
                            <div className="col-6 text-center p-4">
                                <button type="button" className="cena2_cont active">
                                    <p>Mesas y asientos de:</p>
                                    <h3>Cena de clausura</h3>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-2 mb-4">
                                {/*Barra de busqueda*/}
                                <input id="input_buscador" onKeyUp={inputBusqueda} type="text" className="form-control" name="buscar" aria-label="" aria-describedby="basic-addon1" placeholder="Buscar"></input>
                                <div class="col-12 mt-2" id="div_asignados">
                                    <ol id="lista_asignados">
                                    </ol>
                                </div>
                                <div className="asientos-wrapper">
                                    <div className="card-header">
                                        <h5 className="green-text text-center">
                                            Tipos de asientos
                                        </h5>
                                    </div>
                                    <div className="asientos-body row">
                                        <div className="col-lg-4 col-md-3">
                                            <div className="asientos">
                                                <div className="green2"></div>
                                                <span>Disponible</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-3 ">
                                            <div className="asientos">
                                                <div className="pink"></div>
                                                <span>Ocupado</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-3">
                                            <div className="asientos">
                                                <div className="gris2"></div>
                                                <span>Reservado</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 position-relative mt-4 mb-4">
                                <section className="zoom-container">
                                    <div id="zoomtext">
                                        <div className="row-1">
                                            <div className="escenario2"></div>
                                        </div>
                                        <div
                                            className="row-2"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            <div className="mesa_1 mesa-circular circle-wrapper ">{sillas['1']}</div>
                                            <div className="mesa_2 mesa-cuadrada circle-wrapper ">{sillas['2']}</div>
                                            <div className="mesa_3 mesa-circular circle-wrapper ">{sillas['3']}</div>
                                            <div className="mesa_4 mesa-cuadrada circle-wrapper ">{sillas['4']}</div>
                                            <div className="mesa_5 mesa-circular circle-wrapper ">{sillas['5']}</div>
                                            <div className="mesa_6 mesa-cuadrada circle-wrapper ">{sillas['6']}</div>
                                            <div className="mesa_7 mesa-circular circle-wrapper ">{sillas['7']}</div>
                                            <div className="mesa_8 mesa-cuadrada circle-wrapper ">{sillas['8']}</div>
                                        </div>
                                        <div
                                            className="row-4"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="mesa_16 mesa-cuadrada circle-wrapper ">{sillas['16']}</div>
                                            <div className="mesa_15 mesa-circular circle-wrapper ">{sillas['15']}</div>
                                            <div className="mesa_14 mesa-cuadrada circle-wrapper ">{sillas['14']}</div>
                                            <div className="mesa_13 mesa-circular circle-wrapper ">{sillas['13']}</div>
                                            <div className="mesa_12 mesa-cuadrada circle-wrapper ">{sillas['12']}</div>
                                            <div className="mesa_11 mesa-circular circle-wrapper ">{sillas['11']}</div>
                                            <div className="mesa_10 mesa-cuadrada circle-wrapper ">{sillas['10']}</div>
                                            <div className="mesa_9 mesa-circular circle-wrapper ">{sillas['9']}</div>

                                        </div>

                                        <div
                                            className="row-6"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="mesa_17 mesa-circular circle-wrapper ">{sillas['17']}</div>
                                            <div className="mesa_18 mesa-cuadrada circle-wrapper ">{sillas['18']}</div>
                                            <div className="mesa_19 mesa-circular circle-wrapper ">{sillas['19']}</div>
                                            <div className="mesa_20 mesa-cuadrada circle-wrapper ">{sillas['20']}</div>
                                            <div className="mesa_21 mesa-circular circle-wrapper ">{sillas['21']}</div>
                                            <div className="mesa_22 mesa-cuadrada circle-wrapper ">{sillas['22']}</div>
                                            <div className="mesa_23 mesa-circular circle-wrapper ">{sillas['23']}</div>
                                            <div className="mesa_24 mesa-cuadrada circle-wrapper ">{sillas['24']}</div>

                                        </div>
                                        <div
                                            className="row-7"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="mesa_32 mesa-cuadrada circle-wrapper ">{sillas['32']}</div>
                                            <div className="mesa_31 mesa-circular circle-wrapper ">{sillas['31']}</div>
                                            <div className="mesa_30 mesa-cuadrada circle-wrapper ">{sillas['30']}</div>
                                            <div className="mesa_29 mesa-circular circle-wrapper ">{sillas['29']}</div>
                                            <div className="mesa_28 mesa-cuadrada circle-wrapper ">{sillas['28']}</div>
                                            <div className="mesa_27 mesa-circular circle-wrapper ">{sillas['27']}</div>
                                            <div className="mesa_26 mesa-cuadrada circle-wrapper ">{sillas['26']}</div>
                                            <div className="mesa_25 mesa-circular circle-wrapper ">{sillas['25']}</div>

                                        </div>
                                        <div
                                            className="row-8"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="mesa_33 mesa-circular circle-wrapper ">{sillas['33']}</div>
                                            <div className="mesa_34 mesa-cuadrada circle-wrapper ">{sillas['34']}</div>
                                            <div className="mesa_35 mesa-circular circle-wrapper ">{sillas['35']}</div>
                                            <div className="mesa_36 mesa-cuadrada circle-wrapper ">{sillas['36']}</div>
                                            <div className="mesa_37 mesa-circular circle-wrapper ">{sillas['37']}</div>
                                            <div className="mesa_38 mesa-cuadrada circle-wrapper ">{sillas['38']}</div>
                                            <div className="mesa_39 mesa-circular circle-wrapper ">{sillas['39']}</div>
                                            <div className="mesa_40 mesa-cuadrada circle-wrapper ">{sillas['40']}</div>


                                        </div>

                                        <div
                                            className="row-9"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="mesa_48 mesa-cuadrada circle-wrapper ">{sillas['48']}</div>
                                            <div className="mesa_47 mesa-circular circle-wrapper ">{sillas['47']}</div>
                                            <div className="mesa_46 mesa-cuadrada circle-wrapper ">{sillas['46']}</div>
                                            <div className="mesa_45 mesa-circular circle-wrapper ">{sillas['45']}</div>
                                            <div className="mesa_44 mesa-cuadrada circle-wrapper ">{sillas['44']}</div>
                                            <div className="mesa_43 mesa-circular circle-wrapper ">{sillas['43']}</div>
                                            <div className="mesa_42 mesa-cuadrada circle-wrapper ">{sillas['42']}</div>
                                            <div className="mesa_41 mesa-circular circle-wrapper ">{sillas['41']}</div>


                                        </div>

                                        <div
                                            className="row-9"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                width: "100%",
                                            }}
                                        >
                                            <div className="mesa_49 mesa-circular circle-wrapper ">{sillas['49']}</div>
                                            <div className="mesa_50 mesa-cuadrada circle-wrapper ">{sillas['50']}</div>
                                            <div className="mesa_51 mesa-circular circle-wrapper ">{sillas['51']}</div>
                                            <div className="mesa_52 mesa-cuadrada circle-wrapper ">{sillas['52']}</div>
                                            <div className="mesa_53 mesa-circular circle-wrapper ">{sillas['53']}</div>
                                            <div className="mesa_54 mesa-cuadrada circle-wrapper ">{sillas['54']}</div>
                                            <div className="mesa_55 mesa-circular circle-wrapper ">{sillas['55']}</div>
                                            <div className="mesa_56 mesa-cuadrada circle-wrapper ">{sillas['56']}</div>
                                            {/* <div className="mesa_57 circle-wrapper ">{sillas['57']}</div>
                                            <div className="mesa_58 circle-wrapper ">{sillas['58']}</div>
                                            <div className="mesa_59 circle-wrapper ">{sillas['59']}</div>
                                            <div className="mesa_60 circle-wrapper ">{sillas['60']}</div>
                                            <div className="mesa_61 circle-wrapper ">{sillas['61']}</div>
                                            <div className="mesa_62 circle-wrapper ">{sillas['62']}</div>
                                            <div className="mesa_63 circle-wrapper ">{sillas['63']}</div>
                                            <div className="mesa_64 circle-wrapper ">{sillas['64']}</div>
                                            <div className="mesa_65 circle-wrapper ">{sillas['65']}</div>
                                            <div className="mesa_66 circle-wrapper ">{sillas['66']}</div>
                                            <div className="mesa_67 circle-wrapper ">{sillas['67']}</div>
                                            <div className="mesa_68 circle-wrapper ">{sillas['68']}</div>
                                            <div className="mesa_69 circle-wrapper ">{sillas['69']}</div>
                                            <div className="mesa_70 circle-wrapper ">{sillas['70']}</div>
                                            <div className="mesa_71 circle-wrapper ">{sillas['71']}</div>
                                            <div className="mesa_72 circle-wrapper ">{sillas['72']}</div>
                                            <div className="mesa_73 circle-wrapper ">{sillas['73']}</div>
                                            <div className="mesa_74 circle-wrapper ">{sillas['74']}</div>
                                            <div className="mesa_75 circle-wrapper ">{sillas['75']}</div>
                                            <div className="mesa_76 circle-wrapper ">{sillas['76']}</div>
                                            <div className="mesa_77 circle-wrapper ">{sillas['77']}</div>
                                            <div className="mesa_78 circle-wrapper ">{sillas['78']}</div>
                                            <div className="mesa_79 circle-wrapper ">{sillas['79']}</div>
                                            <div className="mesa_80 circle-wrapper ">{sillas['80']}</div>
                                            <div className="mesa_81 circle-wrapper ">{sillas['81']}</div> */}

                                        </div>

                                        <div
                                            className="row-10"
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexWrap: "wrap",
                                                width: "100%",
                                            }}
                                        >
                                        </div>

                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>}
            </Fragment>
        </div>
    );
};

export default AsientosClausura;
