import React, { Component, useState, useEffect, Fragment } from "react";
import Countup from "react-countup";
import logo from '../../assets/images/endless-logo.png';
import { Doughnut, Line, Bar } from "react-chartjs-2";
import ChartistGraph from "react-chartist";
import {
    taskChartData,
    taskChartOptions,
    barChartData,
    barChartOptions,
    projectsmall,
    projectsmall1,
    carnetpagado,
    carnetpagadoopts,
} from "./chart-data";
import { MoreHorizontal, Users, UserCheck, UserX } from "react-feather";

const primary = localStorage.getItem("primary_color") || "#4466f2";
const secondary = localStorage.getItem("secondary_color") || "#1ea6ec";
//const [user, setUser] = useState({})

const HomeComponent1 = (props) => {
    const [estadoH, setEstadoH] = useState(0)
    const [estadoM, setEstadoM] = useState(0)
    const [estadoMonto, setEstadoMonto] = useState(0)
    const [estadoHM, setEstadoHM] = useState({})
    const [carnetPago, setCarnetPago] = useState({})

    useEffect(() => {
        setEstadoHM({
            labels: ['Hombres', 'Mujeres',],
            datasets: [
                {
                    lagend: 'none',
                    data: [props.conteoH, props.conteoM,],
                    borderColor: [primary, '#f85370', secondary],
                    backgroundColor: [primary, '#f85370', secondary],
                    fill: 'origin',
                }
            ]
        })
    }, [props]);

    const listaEstados = props.estados.map(element =>
        <option value={element.ID_ESTADO}>{element.NOMBRE_ESTADO}</option>
    )

    const tablaEstados = props.tablaEstados.map(elements =>
        <tr>
            <td>{elements.estados}</td>
            <td className="text-center">{elements.personas_registradas}</td>
            <td className="text-center">{elements.personas_pagadas}</td>
        </tr>
    )

    const hmEstado = async (idEstado) => {
        let response = await fetch('/rt_dashboard_hm', {
            method: 'POST',
            body: JSON.stringify({ idEstado }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            console.log(res);
            setEstadoH(res.hombres)
            setEstadoM(res.mujeres)
        }
        catch {

        }
    }

    const montoEstado = async (idEstado) => {
        let response = await fetch('/rt_dashboard_monto', {
            method: 'POST',
            body: JSON.stringify({ idEstado }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            console.log(res.total);
            setEstadoMonto(res.total ? res.total : 0)
        }
        catch {

        }
    }

    const hmEstadoGrafica = async (idEstado) => {
        let response = await fetch('/rt_dashboard_hm', {
            method: 'POST',
            body: JSON.stringify({ idEstado }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            setEstadoHM({
                labels: ['Hombres', 'Mujeres',],
                datasets: [
                    {
                        lagend: 'none',
                        data: [res.hombres, res.mujeres,],
                        borderColor: [primary, '#f85370', secondary],
                        backgroundColor: [primary, '#f85370', secondary],
                        fill: 'origin',
                    }
                ]
            })
        }
        catch {

        }
    }

    const pagoCarnet = async (idEstado) => {
        let response = await fetch('/rt_dashboard_carnet', {
            method: 'POST',
            body: JSON.stringify({ idEstado }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            setCarnetPago({
                labels: ['Pagado', 'Sin Pagar',],
                datasets: [
                    {
                        lagend: 'none',
                        data: [res.pagado, res.sin_pago,],
                        borderColor: [primary, '#f85370', secondary],
                        backgroundColor: [primary, '#f85370', secondary],
                        fill: 'origin',
                    }
                ]
            })
        }
        catch {

        }
    }

    return (
        <Fragment>
            <div className="col-md-14">
                <div className="card">
                    <div className="card-body">
                        <div className="logo-wrapper">
                            <img src={logo} width="200" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab-content active default" id="tab-1">
                <div className="row">
                    {/*Tabla para asistentes*/}
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <Users />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Total De Personas Registradas</h6>
                                        <h2 className="font-primary">{props.registradosAsistentes}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <UserCheck />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Total De Personas Pagadas</h6>
                                        <h2 className="font-primary">{props.pagadosAsistentes}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <UserX />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Personas por confirmar pago</h6>
                                        <h2 className="font-primary">{props.sinPagarAsistentes}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={props.hombres} />
                                    </h1>
                                    <h6 className="mb-0">Total De Hombres Registrados</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={props.mujeres} />
                                    </h1>
                                    <h6 className="mb-0">Total De Mujeres Registradas</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={props.cortesia} />
                                    </h1>
                                    <h6 className="mb-0">Registros De Cortesia</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card-header project-header">
                            <div className="row">
                                <table className="table">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">ESTADOS</th>
                                            <th scope="col" className="text-center">PERSONAS REGISTRADAS</th>
                                            <th scope="col" className="text-center">PERSONAS PAGADAS</th>
                                        </tr>
                                    </thead>
                                    {tablaEstados}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

// class HomeComponent extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             open: false,
//             p: props,
//             m: 'mensaje'
//         };
//         console.log('Los props', props);

//     }

//     componentWillUnmount() {
//         document.removeEventListener("mousedown", this.handleClickOutside);
//     }
//     componentDidMount() {
//         document.addEventListener("mousedown", this.handleClickOutside);
//     }
//     handleClickOutside = (event) => {
//         this.setState({
//             open: false,
//         });
//     };
//     handleButtonClick = () => {
//         this.setState((state) => {
//             return {
//                 open: !state.open,
//             };
//         });
//     };
// }

export default HomeComponent1;
