import React, { Component, useState, useEffect, Fragment } from "react";
import Countup from "react-countup";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import { Chart } from "react-google-charts";
import ChartistGraph from "react-chartist";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import "react-datepicker/dist/react-datepicker.css";


import {
    taskChartData,
    taskChartOptions,
    barChartData,
    barChartOptions,
    projectsmall,
    projectsmall1,
    carnetpagado,
    carnetpagadoopts,
} from "./chart-data";
import { MoreHorizontal, Users, UserCheck, UserX } from "react-feather";

const primary = localStorage.getItem("primary_color") || "#4466f2";
const secondary = localStorage.getItem("secondary_color") || "#1ea6ec";
//const [user, setUser] = useState({})

const HomeComponent1 = (props) => {
    const [selectFechaInicio, setSelectFechaInicio] = useState(null)
    const [selectFechaFin, setSelectFechaFin] = useState(null)
    const [fechaInicio, setFechaInicio] = useState(new Date)
    const [fechaFin, setfechaFin] = useState(new Date)
    const [fecha, setFecha] = useState(new Date())
    const [dropdownOpen, setOpen] = useState(false);
    const [seleccion, setSeleccion] = useState('Carnet')
    const [producto, setProducto] = useState(1)

    const [preregistros, setPreregistros] = useState(0)
    const [pendientes, setPendientes] = useState(0)
    const [pagados, setPagados] = useState(0)
    const [montoTotal, setMontoTotal] = useState(0)
    const [montoTotalNeto, setMontoTotalNeto] = useState(0)
    const [cortesias, setCortesias] = useState(0)
    const [pagadosCortesia, setPagadosCortesia] = useState(0)
    const [pagadosHombres, setPagadosHombres] = useState(0)
    const [pagadosMujeres, setPagadosMujeres] = useState(0)
    const [tablaEstados, setTablaEstados] = useState([])
    const [datosGrafica, setDatosGrafica] = useState([])
    const [productos, setProductos] = useState([])
    const [hoteles, setHoteles] = useState({})
    // const [palacioSencillo, setPalacioSencillo] = useState(0)
    // const [palacioDoble, setPalacioDoble] = useState(0)
    // const [palacioPendiente, setPalacioPendiente] = useState(0)
    // const [princessSencillo, setPrincessSencillo] = useState(0)
    // const [princessDoble, setPrincessDoble] = useState(0)
    // const [princessPendiente, setPrincessPendiente] = useState(0)

    let date1 = null
    let date2 = null

    useEffect(() => {
        setData(props)
    }, [props]);

    const setData = (params) => {
        setPreregistros(params.preregistros)
        setPendientes(params.pendientes)
        setPagados(params.pagados)
        setMontoTotal(params.montoTotal)
        setMontoTotalNeto(params.montoTotalNeto)
        setCortesias(params.cortesias)
        setPagadosCortesia(params.pagadosCortesia)
        setPagadosHombres(params.pagadosHombres)
        setPagadosMujeres(params.pagadosMujeres)
        setTablaEstados(params.tablaEstados)
        setDatosGrafica(params.grafica)
        setProductos(params.productos)
        setHoteles(params.hoteles)
        // setPalacioSencillo(params.palacioSencillo)
        // setPalacioDoble(params.palacioDoble)
        // setPalacioPendiente(params.palacioPendiente)
        // setPrincessSencillo(params.princessSencillo)
        // setPrincessDoble(params.princessDoble)
        // setPrincessPendiente(params.princessPendiente)
    }

    const toggle = () => setOpen(!dropdownOpen);

    const inicioFecha = (fech) => {
        return (
            <div className="input-group">
                <p>De:</p><DatePicker className="form-control digits" selected={fech} onChange={(date) => {
                    date1 = date
                    if (date1 && date2) diasGrafica(0, date1, date2, 2)
                    setSelectFechaInicio(inicioFecha(date))
                }} />
            </div>
        )
    }

    const finFecha = (fech) => {
        return (
            <div className="input-group">
                <p>Hasta:</p><DatePicker className="form-control digits" selected={fech} onChange={(date) => {
                    date2 = date
                    if (date1 && date2) diasGrafica(0, date1, date2, 2)
                    console.log(date1, date2);
                    setSelectFechaFin(finFecha(date))
                }} />
            </div>
        )
    }

    const obtenerDatos = async (producto) => {
        let response = await fetch('/rt_dashboard', {
            method: 'POST',
            body: JSON.stringify({ evento: 4, producto, dias: 30 }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            setData(res)
        }
        catch (err) {
            console.log(err);
        }
    }

    const diasGrafica = async (dias, fechIn, fechFin, opcion) => {
        let response = await fetch('/rt_dashboard', {
            method: 'PUT',
            body: JSON.stringify({ evento: 4, producto: producto, dias, fechIn, fechFin, opcion }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            console.log(res);
            setDatosGrafica(res.grafica)
        }
        catch (err) {
            console.log(err);
        }
    }

    const estadosTabla = tablaEstados.map((elements, index) =>
        <tr key={`td_estados_tabla_${index}`}>
            <td>{elements.NOMBRE_CES}</td>
            <td className="text-center">{elements.ASISTENTES_PREREGISTRADOS}</td>
            <td className="text-center">{elements.ASISTENTES_PAGADOS}</td>
            <td className="text-center">{elements.ASISTENTES_CORTESIA}</td>
        </tr>
    )

    const product = productos.map((element, index) =>
        <DropdownItem key={`dropdown_item_product_${index}`} onClick={() => {
            setSeleccion(element.NOMBRE_TPS)
            setProducto(element.ID_TPS)
            obtenerDatos(element.ID_TPS)
        }}>{element.NOMBRE_TPS}</DropdownItem>
    )

    const excelRegistros = async (prod) => {
        fetch('/rt_dashboard?p=' + prod, {
            method: 'GET',
        })
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (res) {
                if (res) {
                    let blob = new Blob([res], { type: "application/xlsx" });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    let fileName = seleccion + '.xlsx';
                    link.download = fileName;
                    link.click();
                }
            }).catch(function (err) {
                console.log('Error al cargar registros:', err);
            })
    }

    return (
        <Fragment>
            <div className="tab-content active default" id="tab-1">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header project-header">
                                <div className="row">
                                    <div className="col-6 text-left">
                                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                            <DropdownToggle className="dropbtn" color="primary" caret> {seleccion} </DropdownToggle>
                                            <DropdownMenu>
                                                {product}
                                            </DropdownMenu>
                                        </Dropdown>
                                        {/* <Select options={tipEvento} placeholder={"Evento"} /> */}
                                    </div>
                                    {/* <div className="col-6 text-right">
                                        <button type="button" className="btn btn-success mt-2 mt-sm-0 ml-2 ml-sm-2" onClick={() => {
                                            excelRegistros()
                                        }}>
                                            <i className="fa fa-download"></i> Descargar reporte
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="card-header project-header">
                                <div className="row">
                                    <div className="col-sm-8">
                                        <h5>Pre-registered and paid monthly attendees</h5>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="select2-drpdwn-project select-options">
                                            <select className="form-control form-control-primary btn-square" name="select" onChange={(event) => {
                                                if (event.target.value == 1) {
                                                    setSelectFechaInicio(inicioFecha(fecha))
                                                    setSelectFechaFin(finFecha(fecha))
                                                } else {
                                                    date1 = null
                                                    date2 = null
                                                    setSelectFechaInicio(null)
                                                    setSelectFechaFin(null)
                                                    diasGrafica(event.target.value, new Date, new Date, 1)
                                                }
                                            }}>
                                                <option value="30">Ultimos 30 días</option>
                                                <option value="60">Ultimos 2 meses</option>
                                                <option value="90">Ultimos 3 meses</option>
                                                <option value="1">Seleccionar fechas</option>
                                            </select>
                                            {selectFechaInicio}
                                            {selectFechaFin}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <Chart
                                width={'100%'}
                                height={'500px'}
                                chartType="LineChart"
                                loader={<div>Loading Chart</div>}
                                data={datosGrafica}
                                options={{
                                    hAxis: {
                                        title: 'Date',
                                    },
                                    vAxis: {
                                        title: 'Registrations',
                                    },
                                    colors: ["#4466f2", "#1ea6ec"],

                                }}
                                rootProps={{ 'data-testid': '2' }}
                            />
                        </div>
                    </div>
                    {/* <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <Users />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Total De Registros</h6>
                                        <h2 className="font-primary">{props.registrados}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <UserCheck />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Registros Pagados</h6>
                                        <h2 className="font-primary">{props.pagados}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <UserX />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Registros Sin Pagar</h6>
                                        <h2 className="font-primary">{props.sinPagar}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/*Tabla para asistentes*/}
                    {/* <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <Users />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Total De Asistentes</h6>
                                        <h2 className="font-primary">{props.registradosAsistentes}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <UserCheck />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Asistentes Pagados</h6>
                                        <h2 className="font-primary">{props.pagadosAsistentes}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="media feather-main">
                                    <div className="feather-icon-block">
                                        <UserX />
                                    </div>
                                    <div className="media-body align-self-center">
                                        <h6>Asistentes Sin Pagar</h6>
                                        <h2 className="font-primary">{props.sinPagarAsistentes}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={preregistros} />
                                    </h1>
                                    <h6 className="mb-0">Pre-registered attendees</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={pendientes} />
                                    </h1>
                                    <h6 className="mb-0">Attendees Pending Approval</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={pagados} />
                                    </h1>
                                    <h6 className="mb-0">Paid attendees</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <a style={{ fontSize: 52 }}>{montoTotal}</a>
                                    </h1>
                                    <h6 className="mb-0">Total Amount Paid</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <a style={{ fontSize: 52 }}>{montoTotalNeto}</a>
                                    </h1>
                                    <h6 className="mb-0">Total Net Amount</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={cortesias} />
                                    </h1>
                                    <h6 className="mb-0">Total Cortesia</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>

                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={pagadosMujeres} />
                                    </h1>
                                    <h6 className="mb-0">Total women</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-sm-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="project-widgets text-center">
                                    <h1 className="font-primary counter">
                                        <Countup end={pagadosHombres} />
                                    </h1>
                                    <h6 className="mb-0">Total men</h6>
                                </div>
                            </div>
                            <div className="card-footer project-footer"></div>
                        </div>
                    </div>


                    <div className="card-header project-header page-body">
                        <div className="row">
                            <div className="col-sm-8 mb-4">
                                <h5>Attendees by type of registration</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">Full AGM 4 days program (member)</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">2 days participation program (member)</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">1 day participation program (member)</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">Full AGM 4 days program (Non-member)</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">Attendee's Companion</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">Speaker</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">Award</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">Press</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="project-widgets text-center">
                                            <h1 className="font-primary counter">
                                                <Countup end={pagadosCortesia} />
                                            </h1>
                                            <h6 className="mb-0">IISRP Staff</h6>
                                        </div>
                                    </div>
                                    <div className="card-footer project-footer"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-header project-header text-right">
                                <button type="button" className="btn btn-success mt-2 mt-sm-0 ml-2 ml-sm-2" onClick={() => {
                                    excelRegistros(producto)
                                }}>
                                    <i className="fa fa-download"></i> Descargar reporte
                                </button>
                            </div>
                            <div className="card-header project-header">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <table className="table">
                                            <thead className="thead-dark">
                                                <tr>
                                                    <th scope="col">ESTADOS</th>
                                                    <th scope="col" className="text-center">ASISTENTES PREREGISTRADOS</th>
                                                    <th scope="col" className="text-center">REGISTROS PAGADOS</th>
                                                    <th scope="col" className="text-center">ASISTENTES POR CORTESIA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {estadosTabla}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >
    );
}

// class HomeComponent extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             open: false,
//             p: props,
//             m: 'mensaje'
//         };
//         console.log('Los props', props);

//     }

//     componentWillUnmount() {
//         document.removeEventListener("mousedown", this.handleClickOutside);
//     }
//     componentDidMount() {
//         document.addEventListener("mousedown", this.handleClickOutside);
//     }
//     handleClickOutside = (event) => {
//         this.setState({
//             open: false,
//         });
//     };
//     handleButtonClick = () => {
//         this.setState((state) => {
//             return {
//                 open: !state.open,
//             };
//         });
//     };
// }

export default HomeComponent1;
