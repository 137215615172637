import React, { Fragment, useState, useEffect } from "react";
// import Tablereact from '../common/tabulator/tablereact'
import Select from "react-select";
import Breadcrumb from "../common/breadcrumb";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import notie from 'notie'

// import ModalNuevoRegistro from './modal_nuevo_registro'
// import DropDown from './dropdown'

// import {
//     CheckCircle, XSquare
// } from 'react-feather';

const Prueba = () => {

	const [isSubscribed, setIsSubscribed] = useState(false);
	const [cantidadCarnets, setCantidadCarnets] = useState(1);
	const [tipoAsistente, setTipoAsistente] = useState({
		id: 1,
		nombre: 'Grupo Gasolinero',
		descripcion: 'Empresario gasolinero',
	});

	const [telefonos, setTelefonos] = useState({
		telefonos_carnets: {},
		ptelefono: {},
		pwhatsapp: {},
		ftelefono: {}
	})

	const [catalogos, setCatalogos] = useState({
		evento: {},
		estados: [],
		instituciones: [],
		regimen_fiscal: [],
		paises: [],
		ciudades_contacto: [],
		ciudades_fiscal: [],
	});

	const [esExtranjero, setEsExtranjero] = useState(false);

	const handleChange = (event) => {
		if (event.target.checked) {
			console.log("✅ Checkbox is checked");
		} else {
			console.log("⛔️ Checkbox is NOT checked");
		}
		setIsSubscribed((current) => !current);
	};

	console.log('catalogos', catalogos);

	useEffect(() => {
		fetch('./rt_obtener_informacion_carnet', {
				method: 'POST',
				body: JSON.stringify({}),
				headers: {'Content-Type': 'application/json'}
		}).then(function(res) { return res.json(); })
		.then(function(res) {
			console.log('info de carnet', res);
			setCatalogos((s) => ({ ...s,
				evento: res.evento,
				estados: res.estados || [],
				instituciones: res.instituciones || [],
				regimen_fiscal: res.regimen_fiscal || [],
				paises: res.paises || []
			}))
			console.log('rt_obtener_informacion_carnet', catalogos);
		}).catch(function(err){
				console.log(err);
		})
	}, [])

	const arregloNElementos = (n) => {
		return Array.from({length: n}, (_, i) => i + 1);
	}

	const mostrarCiudades = (tipo, estado) => {
		fetch(`./rt_catalogo_ciudades?estado=${estado}`)
		.then(function(res) { return res.json(); })
		.then(function(res) {
				if(tipo === 'contacto'){
					setCatalogos((s) => ({ ...s, ciudades_contacto: res.result}));
				}else{
					setCatalogos((s) => ({ ...s, ciudades_fiscal: res.result}));
				}
		}).catch(function(err){
				console.log(err);
		})
	}

	const cambiarTipoAsistente = (id) => {
		let info = catalogos.instituciones.find(inst => inst.ID_CIN == id);
		console.log('info', info);
		// setTipoAsistente(info)
		setTipoAsistente((s) => ({ ...s, id: info.ID_CIN, nombre: info.NOMBRE_CIN, descripcion: info.DESCRIPCION_CIN}));
	}

	const datosFormulario = () => {

		let form = new FormData(document.getElementById('form_cortesia'));

		let carnets = form.get('numero_carnets');
		let data = {};
		let i;

		data['carnets'] = []

		let carnet;
		let carnets_validos = true;

		for(i = 1; i <= carnets; i++){
			if(!form.get(`cnombre${i}`) || form.get(`cnombre${i}`) == ''){
				notie.alert({text: `El campo de nombre es requerido (carnet #${i})`, type: 2 , time: 4});
				return;
			}
			if(!form.get(`capellidos${i}`) || form.get(`capellidos${i}`) == ''){
				notie.alert({text: `El campo de apellidos es requerido (carnet #${i})`, type: 2 , time: 4});
				return;
			}
			if(!form.get(`ccorreo${i}`) || form.get(`ccorreo${i}`) == '' || !form.get(`ccorreo${i}`)){
				notie.alert({text: `El campo de correo es requerido (carnet #${i})`, type: 2 , time: 4});
				return;
			}
			if(!correoValido(form.get(`ccorreo${i}`))){
				notie.alert({text: `Ingrese un correo válido (carnet #${i})`, type: 2 , time: 4});
				return;
			}
			if(!form.get(`cwhatsapp${i}`) || form.get(`cwhatsapp${i}`) == ''){
				notie.alert({text: `El campo de telefono es requerido (carnet #${i})`, type: 2 , time: 4});
				return;
			}

			carnet = {}

			carnet[`nombre`]	=	form.get(`cnombre${i}`);
			carnet[`apellido`]	=	form.get(`capellidos${i}`);
			carnet[`email`]		=	form.get(`ccorreo${i}`);
			carnet[`genero`]	=	form.get(`cgenero${i}`);
			carnet['curp'] 		=	''
			carnet[`whatsapp`]	=	form.get(`cwhatsapp${i}`);
			carnet[`iso`]		=	telefonos.telefonos_carnets[`${i}`].iso || '';
			carnet[`code`]		=	telefonos.telefonos_carnets[`${i}`].codigo || '';
			carnet['gratis']	=	1;

			data['carnets'].push(carnet);

		}

		if(!form.get("pnombre") || form.get("pnombre") == ''){
			notie.alert({text: 'El campo de nombre de contacto es requerido', type: 2 , time: 4});
			return;
		}
		if(!form.get("papellidos") || form.get("papellidos") == ''){
			notie.alert({text: 'El campo de apellidos de contacto es requerido', type: 2 , time: 4});
			return;
		}
		if(!form.get("pcorreo") || form.get("pcorreo") == ''){
			notie.alert({text: 'El campo de correo de contacto es requerido', type: 2 , time: 4});
			return;
		}
		if(!correoValido(form.get("pcorreo"))){
			notie.alert({text: 'Ingrese un correo de contacto válido', type: 2 , time: 4});
			return;
		}
		if(!form.get("ptelefono") || form.get("ptelefono") == ''){
			notie.alert({text: 'El campo de telefono de contacto es requerido', type: 2 , time: 4});
			return;
		}

		let requiere_factura = form.get("prequiere_factura") === 'true' ? true : false;

		if(requiere_factura){
			if(!form.get("frazon_social") || form.get("frazon_social") === ''){
				notie.alert({text: 'El campo de razón social es requerido', type: 2, time: 4});
				return;
			}
			if(!form.get("frfc") || form.get("frfc") === ''){
				notie.alert({text: 'El campo de rfc es requerido', type: 2, time: 4});
				return;
			}
			if(!form.get("fregimen_fiscal") || form.get("fregimen_fiscal") === ''){
				notie.alert({text: 'El campo de regimen fiscal es requerido', type: 2, time: 4});
				return;
			}
			if(!form.get("fcfdi") || form.get("fcfdi") === ''){
				notie.alert({text: 'El campo de cfdi es requerido', type: 2, time: 4});
				return;
			}
			if(!form.get("fcodigo_postal") || form.get("fcodigo_postal") === ''){
				notie.alert({text: 'El campo de codigo postal es requerido', type: 2, time: 4});
				return;
			}
		}

		if(!form.get("ptelefono") || form.get("ptelefono") == ''){
			notie.alert({text: 'El campo de telefono de contacto es requerido', type: 2 , time: 4});
			return;
		}

		data['pnotificaciones']		= form.get('pnotificaciones') ? 1 : 0;
		data['pterminos']			= form.get('pterminos') ? 1 : 0;

		if(data['pterminos'] === 0){
			notie.alert({text: 'Debe aceptar los términos y condiciones', type: 2, time: 4});
		}

		data['cantidad_carnets']	= form.get("numero_carnets");
		data['pnombre']				= form.get("pnombre");
		data['papellidos']			= form.get("papellidos");
		data['pcorreo']				= form.get("pcorreo");
		data['ptelefono_iso']		= telefonos.ptelefono.iso		|| ''
		data['pwhatsapp_iso']		= telefonos.pwhatsapp.iso		|| ''
		data['ptelefono_codigo']	= telefonos.ptelefono.codigo	|| ''
		data['pwhatsapp_codigo']	= telefonos.pwhatsapp.codigo	|| ''
		data['ptelefono']			= form.get("ptelefono");
		data['pwhatsapp']			= form.get("pwhatsapp");
		data['ptipo']				= form.get("ptipo");
		data['pgrupo_gasolinero']	= form.get("pgrupo_gasolinero");
		data['pestacion_servicio']	= form.get("pestacion_servicio");
		data['pnacionalidad']		= form.get("pnacionalidad");
		data['pestado']				= form.get("pestado");
		data['pciudad']				= form.get("pciudad");
		data['pextranjero_pais']	= form.get('pextranjero_pais');
		data['pextranjero_estado']	= form.get('pextranjero_estado');
		data['pextranjero_ciudad']	= form.get('pextranjero_ciudad');

		data['prequiere_factura']	= requiere_factura ? 1 : 0;
		data['frazon_social']		= requiere_factura ? form.get("frazon_social") : '';
		data['frfc']				= requiere_factura ? form.get("frfc") : '';
		data['fregimen_fiscal']		= requiere_factura ? form.get("fregimen_fiscal") : '';
		data['fmetodo_pago']		= requiere_factura ? form.get("fmetodo_pago") : '';
		data['fcfdi']				= requiere_factura ? form.get("fcfdi") : '';
		data['fcalle']				= requiere_factura ? form.get("fcalle") : '';
		data['fcolonia']			= requiere_factura ? form.get("fcolonia") : '';
		data['fnumero_exterior']	= requiere_factura ? form.get("fnumero_exterior") : '';
		data['fnumero_interior']	= requiere_factura ? form.get("fnumero_interior") : '';
		data['fcodigo_postal']		= requiere_factura ? form.get("fcodigo_postal") : '';
		data['festado']				= requiere_factura ? form.get("festado") : '';
		data['fciudad']				= requiere_factura ? form.get("fciudad") : '';
		data['fcorreo']				= requiere_factura ? form.get("fcorreo") : '';
		data['ftelefono_iso']		= requiere_factura ? (telefonos.ftelefono.iso || ''): '';
		data['ftelefono_codigo']	= requiere_factura ? (telefonos.ftelefono.codigo || ''): '';
		data['ftelefono']			= requiere_factura ? form.get("ftelefono") : '';
		data['fcomentarios']		= requiere_factura ? form.get("fcomentarios") : '';
		data['cantidad']			= form.get('cantidad_carnets');

		data['evento']			= catalogos.evento.ID_EVE_PSP;
		data['tps']			= catalogos.evento.ID_TPS_PSP;
		data['psp']			= catalogos.evento.ID_PSP;

		fetch('./rt_agregar_cortesia', {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {'Content-Type': 'application/json'}
		}).then(function(res) { return res.json(); })
		.then(function(res) {
			console.log('rt_agregar_cortesia res', res);
			if(res.status === 'OK' && res.result.ESTATUS === 'OK'){
				notie.alert({text: 'Agregado correctamente', type: 2, time: 4});
			}else{
				throw Error(res.status);
			}
		}).catch(function(err){
			notie.alert({text: 'No se pudo agregar, inténtelo más tarde', type: 4, time: 5});
		})

	}

	const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

	const correoValido = (mail) => {
		return regex.test(mail)
	}

	return (
		<div>
			<Fragment>
				<div className="col-sm-12 mt-5">
					<div className="row">
						<div className="col-sm-12 mb-2 mt-3">
							<Breadcrumb title="Cortesías" parent="Inicio" />
						</div>
					</div>

					<form id="form_cortesia" className="form theme-form">
						<div className="row">
							<div className="col-md-6">
								<div className="row">
									<div className="col-12">
										<h5 className="mb-3">Total Carnets</h5>
										<div className="card">
											<div className="carnet-body">
												<div className="form-group">
													<label
														htmlFor="numero_carnets"
														style={{ fontWeight: 600 }}
													>
														Número de carnets
													</label>
													<select
														className="form-control digits"
														id="numero_carnets"
														name="numero_carnets"
														onChange={(e) => {console.log('Canidad carnets', e); setCantidadCarnets(e.target.value);}}
														defaultValue={1}
													>
														<option value={1}>1</option>
														<option value={2}>2</option>
														<option value={3}>3</option>
														<option value={4}>4</option>
														<option value={5}>5</option>
														<option value={6}>6</option>
														<option value={7}>7</option>
														<option value={8}>8</option>
														<option value={9}>9</option>
														<option value={10}>10</option>
													</select>
												</div>
											</div>
										</div>
									</div>
									<div className="col-12">
										<h5 className="mb-3">Lista de asistentes</h5>
										{
											arregloNElementos(cantidadCarnets).map((numero) => 
										<div className="card" key={`carnet_${numero}`}>
											<div className="carnet-body" id={`carnet_${numero}`}>
												<div
													className="d-flex flex-wrap mt-4 "
													style={{ justifyContent: "space-between" }}
												>
													<h5>Carnet {numero}</h5>
													<p>Gratis</p>
												</div>
												<div className="form-group">
													<label
														style={{ fontWeight: 600 }}
														htmlFor={`ctipo${numero}`}
													>
														Tipo de Carnet
													</label>
													<select
														className="form-control digits"
														id={`ctipo${numero}`}
														name={`ctipo${numero}`}
														defaultValue={'individual'}
													>
														<option value="" disabled>
															Selecciona una opción
														</option>
														<option value="individual">Individual</option>
													</select>
												</div>
												<div className="form-group">
													<label
														style={{ fontWeight: 600 }}
														htmlFor={`cgenero${numero}`}
													>
														Género
													</label>
													<select
														className="form-control digits"
														id={`cgenero${numero}`}
														name={`cgenero${numero}`}
														defaultValue={1}
													>
														<option value="" disabled>
															Seleccione una opción
														</option>
														<option value="1">Másculino</option>
														<option value="2">Femenino</option>
													</select>
												</div>
												<div className="form-group">
													<label
														style={{ fontWeight: 600 }}
														htmlFor={`cnombre${numero}`}
													>
														Nombre(s)
													</label>
													<input
														className="form-control"
														id={`cnombre${numero}`}
														name={`cnombre${numero}`}
														type="text"
														placeholder=""
													/>
												</div>
												<div className="form-group">
													<label
														style={{ fontWeight: 600 }}
														htmlFor={`capellidos${numero}`}
													>
														Apellidos
													</label>
													<input
														className="form-control"
														id={`capellidos${numero}`}
														name={`capellidos${numero}`}
														type="text"
														placeholder=""
													/>
												</div>
												<div className="form-group">
													<label
														style={{ fontWeight: 600 }}
														htmlFor={`ccorreo${numero}`}
													>
														Correo Electrónico
													</label>
													<input
														className="form-control"
														id={`ccorreo${numero}`}
														name={`ccorreo${numero}`}
														type="text"
														placeholder=""
													/>
												</div>
												<div className="form-group">
													<label
														style={{ fontWeight: 600 }}
														htmlFor={`cwhatsapp${numero}`}
													>
														Whatsapp
													</label>
													<br></br>
													<IntlTelInput
													fieldName={`cwhatsapp${numero}`}
													containerClassName="intl-tel-input w-100"
													inputClassName="form-control"
													preferredCountries={['mx']}
													onPhoneNumberChange={(valido, telefono, propiedades) => {
														console.log('Datos de teléfono de convencionista', valido, telefono, propiedades);
														telefonos.telefonos_carnets[`${numero}`] = {
															valido,
															telefono,
															iso: propiedades.iso2,
															codigo: propiedades.dialCode
														};
													}}
													/>
												</div>
											</div>
										</div>
											)
										}
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-aviso card-aviso-carnet mt-4 card">
									<div
										className="carnet-title card-title"
										style={{ background: "#520131" }}
									>
										<h5
											className="text-center w-100 m-0"
											style={{ color: "#ffffff" }}
										>
											El carnet incluye lo siguiente:
										</h5>
									</div>
									<div className="carnet-body">
										<p>
											- Acceso a los 2 días del evento. Conferencias y
											Exposición.
										</p>{" "}
										<p>- Acceso a cenas/shows.</p>{" "}
										<p>- Participación por los regalos de patrocinadores.</p>{" "}
										<p>- Entrada individual e intransferible.</p>{" "}
										<p>- No incluye hospedaje ni transportación</p>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<h5 className="mb-3">Datos Contacto</h5>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="pnombre"
									>
										Nombre(s)
									</label>
									<input className="form-control" id="pnombre" name="pnombre" type="text" placeholder=""/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="papellidos"
									>
										Apellidos
									</label>
									<input
										className="form-control"
										id="papellidos"
										name="papellidos"
										type="text"
										placeholder=""
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="pcorreo"
									>
										Correo Electrónico
									</label>
									<input
										className="form-control"
										id="pcorreo"
										name="pcorreo"
										type="text"
										placeholder=""
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="ptelefono"
									>
										Teléfono
									</label>
									<br></br>
									<IntlTelInput
									fieldName="ptelefono"
									containerClassName="intl-tel-input w-100"
									inputClassName="form-control"
									preferredCountries={['mx']}
									onPhoneNumberChange={(valido, telefono, propiedades) => {
										telefonos.ptelefono = {
											valido,
											telefono,
											iso: propiedades.iso2,
											codigo: propiedades.dialCode
										};
									}}
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="pwhatsapp"
									>
										Whatsapp
									</label>
									<br></br>
									<IntlTelInput
									fieldName="pwhatsapp"
									containerClassName="intl-tel-input w-100"
									inputClassName="form-control"
									preferredCountries={['mx']}
									onPhoneNumberChange={(valido, telefono, propiedades) => {
										telefonos.pwhatsapp = {
											valido,
											telefono,
											iso: propiedades.iso2,
											codigo: propiedades.dialCode
										};
									}}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label
										htmlFor="ptipo"
										style={{ fontWeight: 600 }}
									>
										Tipo Asistente
									</label>
									<select
										className="form-control digits"
										id="ptipo"
										name="ptipo"
										onChange={(e) => {cambiarTipoAsistente(e.target.value)}}
									>
										<option value="" hidden>Selecciona una opción</option>
									{
										catalogos.instituciones.map(item => 
											<option key={`key_institucion_${item.ID_CIN}`} value={item.ID_CIN}>{item.DESCRIPCION_CIN}</option>
										)
									}
									</select>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="pgrupo_gasolinero"
									>
										{tipoAsistente.nombre}
									</label>
									<input
										className="form-control"
										id="pgrupo_gasolinero"
										name="pgrupo_gasolinero"
										type="tel"
										placeholder=""
									/>
								</div>
							</div>
							<div className="col-md-6" style={{display: tipoAsistente.id == 1 ? 'block' : 'none'}}>
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="pestacion_servicio"
									>
										Estación de Servicio
									</label>
									<input
										className="form-control"
										id="pestacion_servicio"
										name="pestacion_servicio"
										type="tel"
										placeholder=""
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group">
									<label
										htmlFor="pnacionalidad"
										style={{ fontWeight: 600 }}
									>
										Nacionalidad
									</label>
									<select
										className="form-control digits"
										id="pnacionalidad"
										name="pnacionalidad"
										defaultValue={"Mexico"}
										onChange={(e) => {
												if(e.target.value === 'Mexico') setEsExtranjero(false);
												else setEsExtranjero(true);
											}
										}
									>
									<option value="Mexico">Mexicana</option>
									<option value="Extranjero">Extranjera</option>
									</select>
								</div>
							</div>
							{/* Campos de Mexicanos */}
							<div style={{display: esExtranjero ? 'none': 'block'}} className="col-md-6">
								<div className="form-group">
									<label
										htmlFor="pestado"
										style={{ fontWeight: 600 }}
									>
										Estado
									</label>
									<select
										className="form-control digits"
										id="pestado"
										name="pestado"
										onChange={(e) => {mostrarCiudades('contacto', e.target.value)}}
									>
										<option value="" hidden>Seleccione un estado</option>
									{
										catalogos.estados.map(item => 
											<option key={`key_estados_${item.ID_CES}`} value={item.ID_CES}>{item.NOMBRE_CES}</option>
										)
									}
									</select>
								</div>
							</div>
							<div style={{display: esExtranjero ? 'none': 'block'}} className="col-md-6">
								<div className="form-group">
									<label
										htmlFor="pciudad"
										style={{ fontWeight: 600 }}
									>
										Ciudad
									</label>
									<select
										className="form-control digits"
										id="pciudad"
										name="pciudad"
									>
										<option value="" hidden>Seleccione una ciudad</option>
									{
										catalogos.ciudades_contacto.map(item => 
											<option key={`key_ciudad_${item.ID_CCD}`} value={item.ID_CCD}>{item.NOMBRE_CCD}</option>
										)
									}
									</select>
								</div>
							</div>
							{/* Campos de Extranjeros */}
							<div style={{display: !esExtranjero ? 'none': 'block'}} className="col-md-4">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="pextranjero_pais"
									>
										País
									</label>
									<select
										className="form-control digits"
										id="pextranjero_pais"
										name="pextranjero_pais"
									>
										<option value="" hidden>Seleccione</option>
									{
										catalogos.paises.map(item => 
											<option key={`key_rf_${item.ID_CPA}`} value={item.ID_CPA}>{item.NOMBRE_CPA}</option>
										)
									}
									</select>
								</div>
							</div>
							<div style={{display: !esExtranjero ? 'none': 'block'}} className="col-md-4">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="pextranjero_estado"
									>
										Estado/provincia
									</label>
									<input
										className="form-control"
										id="pextranjero_estado"
										name="pextranjero_estado"
										type="text"
										placeholder=""
									/>
								</div>
							</div>
							<div style={{display: !esExtranjero ? 'none': 'block'}} className="col-md-4">
								<div className="form-group">
									<label
										style={{ fontWeight: 600 }}
										htmlFor="pextranjero_ciudad"
									>
										Ciudad
									</label>
									<input
										className="form-control"
										id="pextranjero_ciudad"
										name="pextranjero_ciudad"
										type="text"
										placeholder=""
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<input
										type="checkbox"
										name="prequiere_factura"
										id="showfact"
										value={isSubscribed}
										onChange={handleChange}
									/>
									<label className="ml-2" style={{ fontWeight: 500 }}>
										Requiero Facturación
									</label>
								</div>
							</div>
						</div>
						<div className={isSubscribed ? "" : "d-none"}>
							<div className="row">
								<div className="col-12">
									<h5 className="mb-3">Datos Fiscales</h5>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="frazon_social"
										>
											Razón Social
										</label>
										<input
											className="form-control"
											id="frazon_social"
											name="frazon_social"
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="frfc"
										>
											RFC
										</label>
										<input
											className="form-control"
											id="frfc"
											name="frfc"
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fregimen_fiscal"
										>
											Régimen Fiscal
										</label>
										<select
											className="form-control digits"
											id="fregimen_fiscal"
											name="fregimen_fiscal"
											defaultValue={1}
										>
										<option value="" hidden>Seleccione</option>
									{
										catalogos.regimen_fiscal.map(item => 
											<option key={`key_rf_${item.ID_CRF}`} value={item.ID_CRF}>{item.DESCRIPCION_CRF}</option>
										)
									}
										</select>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fmetodo_pago"
										>
											Método de pago
										</label>
										<select
											className="form-control digits"
											id="fmetodo_pago"
											name="fmetodo_pago"
										>
											<option value="" hidden>Selecciona una opción</option>
											<option value="04">Tarjeta de crédito</option>
											<option value="28">Tarjeta de débito</option>
											<option value="03">Cuenta bancaria</option>
										</select>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fcfdi"
										>
											Uso CFDI
										</label>
										<select
											className="form-control digits"
											id="fcfdi"
											name="fcfdi"
										>
											<option value="" hidden>Selecciona una opción</option>
											<option value="G03">Gastos en general</option>
											<option value="P01">Por definir</option>
											<option value="G01">Adquisición de mercancias</option>
										</select>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fcalle"
										>
											Calle
										</label>
										<input
											className="form-control"
											id="fcalle"
											name="fcalle"
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fcolonia"
										>
											Colonia
										</label>
										<input
											className="form-control"
											id="fcolonia"
											name="fcolonia"
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fnumero_exterior"
										>
											Núm. Exterior
										</label>
										<input
											className="form-control"
											id="fnumero_exterior"
											name="fnumero_exterior"
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fnumero_interior"
										>
											Núm. Interior
										</label>
										<input
											className="form-control"
											id="fnumero_interior"
											name="fnumero_interior"
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fcodigo_postal"
										>
											Código Postal
										</label>
										<input
											className="form-control"
											id="fcodigo_postal"
											name="fcodigo_postal"
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="festado"
										>
											Estado
										</label>
										<select
											className="form-control digits"
											id="festado"
											name="festado"
											onChange={(e) => {mostrarCiudades('fiscal', e.target.value)}}
										>
										<option value="" hidden>Seleccione un estado</option>
									{
										catalogos.estados.map(item => 
											<option key={`key_estados_${item.ID_CES}`} value={item.ID_CES}>{item.NOMBRE_CES}</option>
										)
									}
										</select>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fciudad"
										>
											Ciudad
										</label>
										<select
											className="form-control digits"
											id="fciudad"
											name="fciudad"
										>
										<option value="" hidden>Seleccione una ciudad</option>
									{
										catalogos.ciudades_fiscal.map(item => 
											<option key={`key_ciudad_${item.ID_CCD}`} value={item.ID_CCD}>{item.NOMBRE_CCD}</option>
										)
									}
										</select>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="fcorreo"
										>
											Correo Electrónico
										</label>
										<input
											className="form-control"
											id="fcorreo"
											name="fcorreo"
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group">
										<label
											style={{ fontWeight: 600 }}
											htmlFor="ftelefono"
										>
											Teléfono
										</label>
										<br></br>
										<IntlTelInput
										fieldName="ftelefono"
										containerClassName="intl-tel-input w-100"
										inputClassName="form-control"
										preferredCountries={['mx']}
										onPhoneNumberChange={(valido, telefono, propiedades) => {
											telefonos.ftelefono = {
												valido,
												telefono,
												iso: propiedades.iso2,
												codigo: propiedades.dialCode
											};
										}}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<label
										htmlFor="fcomentarios"
										style={{ fontWeight: 600 }}
									>
										Comentarios
									</label>
									<div className="form-group">
										<textarea
											id="fcomentarios"
											name="fcomentarios"
											cols="30"
											rows="5"
											className="w-100"
										></textarea>
									</div>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<div className="mb-2">
										<input type="checkbox" name="pnotificaciones" />
										<label className="ml-2" style={{ fontWeight: 500 }}>
											Deseo recibir noticias y comunicados por WhatsApp.
										</label>
									</div>
									<div className="mb-2">
										<input type="checkbox" name="pterminos"/>
										<label className="ml-2" style={{ fontWeight: 500 }}>
											{" "}
											He leído y acepto las
											<a
												href="/reencuentro2021/terminos-y-condiciones"
												target="_blank"
												tyle={{ fontWeight: 600 }}
											>
												&nbsp;Políticas de Salud y los Términos y Condiciones
											</a>
										</label>
									</div>
								</div>
								
							</div>
						</div>

						<button type="button" onClick={datosFormulario} className="btn btn-primary mb-4">Enviar</button>
					</form>
				</div>
			</Fragment>
		</div>
	);
};

export default Prueba;
