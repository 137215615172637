import React, { Fragment, useState, useEffect, Component } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from "reactstrap";

import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import { useParams } from "react-router-dom";

import { groupArrayByKey } from "../../constant/funcionesGenerales";

import notie from 'notie'

// import ModalNuevoRegistro from './modal_nuevo_registro'
// import DropDown from './dropdown'

// import {
//     CheckCircle, XSquare
// } from 'react-feather';

const Detalle = () => {

    const params = useParams();

    console.log('params', params);
    // params.id;
    // params.evento;


    const [editarAsistentes, setEditarAsistentes] = useState(false);
    const [actualizandoAsistentes, setActualizandoAsistentes] = useState(false);

    const [registro, setRegistro] = useState({
        valor_estatus_pago: 0,
        estatus_pago: '',
        estatus: [],
        informacion: {},
        subcarnets: [],
        asistentes: [],
        miembro: 0,
        ruta: '',
        hoteles: [],
        tipo_habitacion: []
    })

    const [ddState, setDdState] = useState({
        dropdownOpen: false,
        isOpen: false,
        collapse: false,
    })

    const [descargandoArchivos, setDescargandoArchivos] = useState(false);

    const [descargandoComprobante, setDescargandoComprobante] = useState(false);

    const clasesTipos = {
        "1": "transferencia",
        "2": "paypal",
        "3": "link",
        "4": "terminal",
        "5": "pendiente",
    }

    const clasesEstatus = {
        "0": "cancelado",
        "1": "pendiente",
        "2": "pagado",
        "3": "revisar",
        "4": "pendiente",
        "5": "cortesia",
    }

    useEffect(() => {
        fetch(`../../../rt_convencion/detalle`, {
            method: 'POST',
            body: JSON.stringify({ evento: params.evento, tps: params.tps, folio: params.folio }),
            headers: { 'Content-Type': 'application/json' }
        }).then(function (res) { return res.json(); })
            .then(function (res) {
                console.log('Información:', res);
                if (res.status == 'OK') {
                    let estatus_activo = res.estatus.find((est) => est.ESTATUS == res.result.ESTATUS_PAGO);
                    let subcarnets = groupArrayByKey(res.asistentes, 'ID_SUBCARNET', 'SUBCARNET');
                    subcarnets = Object.values(subcarnets);
                    console.log('subcarnets', subcarnets);

                    console.log('Estatus activo', estatus_activo);
                    res.result.codigo_descuento = res.codigo_descuento;
                    setRegistro((s) => ({ ...s, informacion: res.result, subcarnets, asistentes: res.asistentes, estatus: res.estatus, estatus_pago: estatus_activo.NOMBRE, valor_estatus_pago: estatus_activo.ESTATUS, miembro: res.miembro.ID, ruta: res.ruta.RUTA, tipo_habitacion: res.tipo_habitacion }))
                }
                abrirAccordion();
            }).catch(function (err) {
                console.log('Error al cargar catálogos:', err);
            });
    }, []);

    function toggleDrop() {
        setDdState((s) => ({ ...s, dropdownOpen: !ddState.dropdownOpen }));
    }

    function abrirAccordion() {
        let accordion_items = document.querySelectorAll('.accordion-item>.title')
        for (let item of accordion_items) {
            item.click();
        }
    }

    function clickEstatus(e) {
        let estatus = registro.estatus.find((est) => est.NOMBRE == e.target.innerText);
        let estatus_pagado = registro.estatus.findIndex(item => item.ESTATUS == estatus.ESTATUS && (item.NOMBRE == 'Pagado' || item.NOMBRE == 'Cortesía'))
        let estatus_cancelado = registro.estatus.findIndex(item => item.ESTATUS == estatus.ESTATUS && (item.NOMBRE == 'Cancelado'))
        if (estatus_pagado > -1) {
            notie.confirm({
                text: 'Se enviará el correo con la confirmación del pago, ¿desea continuar?',
                submitText: 'Continuar',
                cancelText: 'Cancelar',
                position: 'top',
                submitCallback: () => { actualizarEstatus(estatus, estatus_pagado) }
            })
        } else if (estatus_cancelado > -1) {
            notie.confirm({
                text: 'Dejará de aparecer en los registros de asistentes, ¿Desea continuar?',
                submitText: 'Continuar',
                cancelText: 'Cancelar',
                position: 'top',
                submitCallback: () => { actualizarEstatus(estatus, estatus_pagado) }
            })
        } else {
            actualizarEstatus(estatus, estatus_pagado);
        }
    }

    function actualizarEstatus(estatus, estatus_pagado) {
        fetch('../../../rt_convencion/detalle/estatus', {
            method: 'POST',
            body: JSON.stringify({ estatus: estatus.ESTATUS, id: registro.informacion.ID }),
            headers: { 'Content-Type': 'application/json' }
        }).then(function (res) { return res.json(); })
        .then(function (res) {
            if (estatus.ESTATUS == 2) {
                enviarCorreo();
                console.log('Enviar correo');
            }
            setRegistro((s) => ({ ...s, estatus_pago: estatus.NOMBRE, valor_estatus_pago: estatus.ESTATUS }))
        }).catch(function (err) {
            console.log(err);
        })
        
        /* fetch('https://cms-events.iisrp.com/app/rt_send_profile', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: { 'Content-Type': 'application/json' }
        }) */

    }

    function descargarArchivosComprobantes() {
        setDescargandoArchivos(true);
        fetch('https://agm-register.iisrp.com/p_comprobantes_evento', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ ID_EVE: registro.informacion.ID_EVE, ID_RC: registro.informacion.ID })
        })
            .then(res => res.json())
            .then(res => {
                setDescargandoArchivos(false);
                console.log('res', JSON.stringify(res));
                if (!res || !res.filename) {
                    notie.alert({ text: 'Sin archivos cargados', type: 5, time: 2 });
                    return;
                }
                let nombre_archivo = res.filename
                console.log('Archivos', nombre_archivo.length);
                for (let index = 0; index < nombre_archivo.length; index++) {
                    let numero = index
                    fetch('https://agm-register.iisrp.com/p_comprobantes_evento_descarga', {
                        headers: { 'Content-Type': 'application/json' },
                        method: 'POST',
                        body: JSON.stringify({ ID_EVE: registro.informacion.ID_EVE, ID_RC: registro.informacion.ID, pos: numero })
                    })
                        .then(res => res.blob())
                        .then(blob => {
                            console.log('blob', blob);
                            let file = window.URL.createObjectURL(blob);
                            let a = document.createElement('a');
                            a.href = file;
                            a.download = `${registro.informacion.CONCEPTO_PAGO}-${nombre_archivo[numero]}`;
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                        });
                }
            })
            .catch(err => {
                console.log('Error', err);
                setDescargandoArchivos(false);
            });
    }

    function enviarCorreo() {
        fetch('https://agm-register.iisrp.com/success_transferencia', {
            method: 'POST',
            body: JSON.stringify({ ruta: registro.ruta, "id_registro": registro.informacion.FOLIO, "id_mi": registro.miembro }),
            headers: { 'Content-Type': 'application/json' }
        }).then(function (res) { return res.json(); })
            .then(function (res) {
                console.log(res);
            }).catch(function (err) {
                console.log(err);
            })
        // body { ruta: "nombre de la ruta del evento ejemplo 'oce2022_p'", "id_registro": "El folio", "id_mi" : "ID_MI del asistente"}
        // https://agm-register.iisrp.com/success_transferencia body { ruta: "nombre de la ruta del evento ejemplo 'oce2022_p'", "id_registro": "El folio", "id_mi" : "ID_MI del asistente"}
    }

    function imprimirPagina() {
        if (descargandoComprobante)
            return;
        setDescargandoComprobante(true);

        fetch('/rt_descargar_archivo_detalle_convencion', {
            method: 'POST',
            body: JSON.stringify({ evento: params.evento, tps: params.tps, folio: params.folio }),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => { console.log('res', res); console.log('res.headers', res.headers); return res.blob(); })
            .then(blob => {
                console.log('res.blob', blob);
                let file = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = file;
                a.download = `Registro Convención y Expo 2023 - Folio #${registro.informacion.FOLIO}.pdf`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                setDescargandoComprobante(false);
            }).catch(err => {
                notie.alert({ text: 'No se puede descargar en este momento, inténtelo más tarde', type: 2, time: 4 });
                setDescargandoComprobante(false);
            })
    }

    function iniciarEditarAsistentes(params) {
        setEditarAsistentes(true);
    }

    function actualizarDatosAsistentes() {
        setActualizandoAsistentes(true);
        let registrosCambiados = [], nombres, apellidos, correo, whatsapp,badge,company,position;

        registro.asistentes.forEach(asistente => {

            nombres = document.getElementById(`input_nombres_${asistente.ID}`).value;
            apellidos = document.getElementById(`input_apellidos_${asistente.ID}`).value;
            correo = document.getElementById(`input_correo_${asistente.ID}`).value;
            whatsapp = document.getElementById(`input_whatsapp_${asistente.ID}`).value;
            badge = document.getElementById(`input_badge_${asistente.ID}`).value;
            company = document.getElementById(`input_company_${asistente.ID}`).value;
            position = document.getElementById(`input_position_${asistente.ID}`).value;

            if (
                asistente.NOMBRES !== nombres ||
                asistente.APELLIDOS !== apellidos ||
                asistente.CORREO !== correo ||
                asistente.WHATSAPP !== whatsapp ||
                asistente.BADGE !== badge ||
                asistente.COMPANY !== company ||
                asistente.POSITION !== position
            ) {
                registrosCambiados.push({
                    ID: asistente.ID,
                    NOMBRES: nombres,
                    APELLIDOS: apellidos,
                    CORREO: correo,
                    WHATSAPP: whatsapp,
                    BADGE: badge,
                    COMPANY: company,
                    POSITION: position
                })
            }
        })

        if (registrosCambiados.length < 1) {
            setEditarAsistentes(false);
            setActualizandoAsistentes(false);
            return;
        }

        fetch('/rt_actualizar_datos_asistente', {
            method: 'POST',
            body: JSON.stringify(registrosCambiados),
            headers: { 'Content-Type': 'application/json' }
        }).then(function (res) { return res.json(); })
            .then(function (res) {
                console.log('rt_actualizar_datos_asistente res', res);
                if (res.status == 'OK') {
                    notie.alert({ text: 'Información actualizada', type: 1, time: 4 });
                    window.location.reload();
                } else {
                    throw Error(res.status);
                }
            }).catch(function (err) {
                setActualizandoAsistentes(false);
                console.log('error rt_actualizar_datos_asistente', err);
            })
    }

    function formatoDinero(valor) {
        valor = valor ? parseFloat(valor).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '0.00';
        return valor;
    }

    function actualizarCamas(id_sub, valor) {
        fetch('../../../rt_actualizar_camas_subcarnet', {
            method: 'POST',
            body: JSON.stringify({ ID_SUBCARNET: id_sub, VALOR: valor }),
            headers: { 'Content-Type': 'application/json' }
        }).then(function (res) { return res.json(); })
            .then(function (res) {
                if (res.status == 'OK') {
                    let asistentes = registro.asistentes;
                    asistentes = asistentes.map(asistente => {
                        if (asistente.ID_SUBCARNET === id_sub) {
                            asistente.CAMAS = parseInt(valor);
                        }
                        return asistente;
                    })
                    setRegistro((s) => ({ ...s, asistentes }))
                    notie.alert({ text: 'Se actualizó el tipo de habitación', type: 1, time: 5 });
                } else {
                    throw Error(res.status)
                }
            }).catch(function (err) {
                notie.alert({ text: 'No se pudo actualizar el tipo de habitación', type: 3, time: 5 });
            })
    }

    return <div id="divToPrint">
        <Fragment
        >
            <div className="col-sm-12 mb-1 mt-3">
                <Breadcrumb
                    title="Registration detail"
                    parent="Registrations"
                />
            </div>

            <div className="row d-flex" style={{ flexWrap: 'nowrap' }}>
                <div className="col-5 col-lg-8">
                    <div
                        className="d-flex justify-content-evenly flex-wrap"
                        style={{ fontSize: 20 }}
                    >
                        <div className="info-container mx-4">
                            <label className="col-form-label text-left">
                                Register ID: &nbsp;
                            </label>
                            <label className="col-form-label text-left font-weight-bold">
                                {registro.informacion.FOLIO || '-'}
                            </label>
                        </div>

                    </div>
                </div>
                <div className="mx-4 col-7 col-lg-4 d-flex">
                    {/* <div className="mb-3 d-flex align-items-end">
                        <button type="button" className="btn btn-primary mx-4 " disabled={descargandoComprobante ? true : false} onClick={imprimirPagina}>
                            <i className="fa fa-download"></i> Download
                        </button>
                    </div> */}
                    <div className="d-flex justify-content-evenly flex-wrap mr-2">
                        <div className="dropdown mx-4 mb-3">
                            <label>Change status:</label>
                            <Dropdown
                                isOpen={ddState.dropdownOpen}
                                toggle={toggleDrop}
                            >
                                <DropdownToggle caret color="success">
                                    {registro.estatus_pago}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        registro.estatus.map((est, i) => {
                                            return <DropdownItem key={`estatus${i}`} defaultValue={est.ESTATUS} onClick={clickEstatus}>{est.NOMBRE}</DropdownItem>
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-4 pb-3 ml-1">
                <div className="info-container mx-4">
                    <label className="col-form-label text-left">
                        Registration date: &nbsp;
                    </label>
                    <label className="col-form-label text-left font-weight-bold">
                        {registro.informacion.FECHA_ALTA || '-'}
                    </label>
                </div>
                <div className="info-container mx-4">
                    <label className="col-form-label text-left">
                        Payment date: &nbsp;
                    </label>
                    <label className="col-form-label text-left font-weight-bold">
                        {registro.informacion.FECHA_PAGO || '-'}
                    </label>
                </div>
                <div className="info-container mx-4">
                    <label className="col-form-label text-left">
                        Payment confirmation: &nbsp;
                    </label>
                    <label className="col-form-label text-left font-weight-bold">
                        {registro.informacion.FECHA_FIN || '-'}
                    </label>
                </div>

            </div>

            <div
                className="default-according panel-accordion"
                id="accordionclose"
            >
                <Accordion atomic={false}>
                    <AccordionItem
                        className="card-header bg-primary"
                        title={
                            <div className="card-title-detalle">
                                <i className="fa fa-user"></i>
                                <h5 className="ml-3 font-weight-bold mb-0">
                                    Personal data
                                </h5>
                            </div>
                        }
                    >
                        <div
                            className="collapse show"
                            id="collapseicon1"
                            aria-labelledby="collapseicon1"
                            data-parent="#accordionoc"
                        >
                            <div className="card container-fluid">
                                <br />
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <form className="theme-form">
                                            <div className="row m-auto">
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            First name: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {registro.informacion.NOMBRES || '-'}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            Last name: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {registro.informacion.APELLIDOS || '-'}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            Email: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {registro.informacion.CORREO || '-'}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                           Phone: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {registro.informacion.CELULAR ? `+${registro.informacion.CELULAR_CODIGO} ${registro.informacion.CELULAR}` : '-'}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            Company: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {registro.informacion.COMPANY || '-'}
                                                        </label>
                                                    </div>
                                                </div>
                                                
                                                {
                                                    registro.informacion.ES_EXTRANJERO === 1 ?
                                                        <div className="col-md-6">
                                                            <div className="info-container">
                                                                <label className="col-form-label text-left font-weight-bold">
                                                                    ES EXTRANJERO
                                                                </label>
                                                            </div>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </AccordionItem>
                    
                    <AccordionItem
                        className="card-header bg-primary"
                        title={
                            <div className="card-title-detalle">
                                <i className="fa fa-usd"></i>
                                <h5 className="ml-3 font-weight-bold mb-0">
                                    Payment details
                                </h5>
                            </div>
                        }
                    >
                        <div
                            className="collapse show"
                            id="collapseicon2"
                            aria-labelledby="collapseicon2"
                            data-parent="#accordionoc"
                        >
                            <div className="card container-fluid">
                                <br />
                                <div className="row m-auto w-100">
                                    <div className="col-md-12 p-0">
                                        <form className="theme-form">
                                            <div className="row m-auto">
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            Payment type: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            <div
                                                                className={`alert alert-light p-2 m-0 rounded ${clasesTipos[registro.informacion.PASARELA_PAGO_VALOR]}`}
                                                                role="alert"
                                                            >
                                                                {registro.informacion.PASARELA_PAGO || '-'}
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            Transaction ID: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {registro.informacion.TRANSACCION || '-'}
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    registro.informacion.APLICO_CODIGO ?
                                                        <Fragment>
                                                            <div className="col-md-6">
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                    Discount code: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {registro.informacion.codigo_descuento.CODIGO}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="info-container">
                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                    Discount amount: &nbsp;
                                                                    </label>
                                                                    <label className="col-form-label text-left">
                                                                        {registro.informacion.codigo_descuento.TIPO === 2 ?
                                                                            `${registro.informacion.codigo_descuento.IMPORTE * 100}%` :
                                                                            `$${formatoDinero(registro.informacion.codigo_descuento.IMPORTE)}`
                                                                        }
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                        : null
                                                }
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            Amount: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {`$${formatoDinero(registro.informacion.TOTAL)}`}
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            Payment status: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            <div
                                                                className={`alert alert-light p-2 m-0 rounded ${clasesEstatus[registro.valor_estatus_pago]}`}
                                                                role="alert"
                                                            >
                                                                {registro.estatus_pago || '-'}
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                            Payment date: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {registro.informacion.FECHA_PAGO || '-'}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="info-container">
                                                        <label className="col-form-label text-left font-weight-bold">
                                                        Payment concept: &nbsp;
                                                        </label>
                                                        <label className="col-form-label text-left">
                                                            {registro.informacion.CONCEPTO_PAGO || '-'}
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    registro.informacion.PASARELA_PAGO_VALOR != 1 ?
                                                        null :
                                                        <div className="col-md-6">
                                                            <div className="info-container">
                                                                <label className="col-form-label text-left font-weight-bold">
                                                                    Comprobante de pago: &nbsp;
                                                                </label>
                                                                <label className="col-form-label text-left">
                                                                    <button type="button" className="btn btn-primary" disabled={descargandoArchivos ? true : false} onClick={descargarArchivosComprobantes}>
                                                                        <i className="fa fa-download"></i> Descargar
                                                                    </button>
                                                                </label>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </AccordionItem>
                    <AccordionItem
                        className="card-header bg-primary"
                        title={
                            <div className="card-title-detalle">
                                <i className="fa fa-person"></i>
                                <h5 className="ml-3 font-weight-bold mb-0">Attendees</h5>
                            </div>
                        }
                    >
                        <div
                            className="collapse show"
                            id="collapseicon3"
                            aria-labelledby="collapseicon3"
                            data-parent="#accordionoc"
                        >
                            <div className="card container-fluid">
                                <br />
                                <div className="row m-auto w-100">
                                    <div className="col-md-12 p-0">
                                        <form className="theme-form">
                                            <div className="row m-auto">
                                                {
                                                    registro.subcarnets.map((subcarnet, i) =>
                                                        <div className="col-md-6 mb-3" key={`subcarnet${i}`}>
                                                            {/* {
                                                                subcarnet.key ?
                                                                    <Fragment>
                                                                        <h6> <strong>Tipo de Carnet:&nbsp;</strong>{`${subcarnet.key}${subcarnet.array[0].HOTEL ? ' CON HOSPEDAJE' : params.tps === 1 ? 'SIN HOSPEDAJE' : ''}`}</h6>
                                                                    </Fragment>
                                                                    : null
                                                            } */}
                                                            <Fragment>
                                                                <div className="row" style={{ height: 0 }}></div>
                                                                {
                                                                    subcarnet.array[0] && subcarnet.array[0].HOTEL ?

                                                                        <Fragment>
                                                                            {!editarAsistentes ?
                                                                                <Fragment><strong>Tipo habitación:&nbsp;</strong>{subcarnet.array[0].CAMAS ? (`${subcarnet.array[0].CAMAS} ${subcarnet.array[0].CAMAS === 1 ? 'CAMA' : 'CAMAS'}`) : 'No seleccionado'}</Fragment>
                                                                                :
                                                                                <Fragment>
                                                                                    <label for="select_camas">Camas:</label>
                                                                                    <select id="select_camas" onChange={(e) => { actualizarCamas(subcarnet.array[0].ID_SUBCARNET, e.target.value) }} defaultValue={subcarnet.array[0].CAMAS}>
                                                                                        <option hidden value={null}>No seleccionado</option>
                                                                                        {registro.tipo_habitacion.map(v =>
                                                                                            <option value={v.CANTIDAD_CAMAS_T} disabled={v.DISABLED}>{v.TIPO_HABITACION} {v.DISABLED ? '(No disponible)' : ''}</option>
                                                                                        )}
                                                                                        {/* <option value={1}>1 cama</option>
                                                                                        <option value={2}>2 camas</option> */}
                                                                                    </select>
                                                                                </Fragment>
                                                                            }

                                                                            <div className="row">
                                                                                <div className="col-12">
                                                                                    <div className="info-container">
                                                                                        <label className="col-form-label text-left font-weight-bold">
                                                                                            Hotel: &nbsp;
                                                                                        </label>
                                                                                        <label className="col-form-label text-left">
                                                                                            {subcarnet.array[0].HOTEL}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Fragment> : null
                                                                }
                                                                {
                                                                    subcarnet.array.map((asistente, i) =>

                                                                        <div className="row" key={`info_asistente_${i}`}>
                                                                            <div className="col-12" style={{ height: 15 }}></div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                        Type of registration: &nbsp;
                                                                                    </label>

                                                                                    <label id={`label_badge_${asistente.ID}`} className="col-form-label text-left">
                                                                                        {asistente.DESCRIPCION_SUB || ''}
                                                                                    </label>

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                        Attendee name: &nbsp;
                                                                                    </label>
                                                                                    {!editarAsistentes ?
                                                                                        <label id={`label_nombres_${asistente.ID}`} className="col-form-label text-left">
                                                                                            {asistente.NOMBRES ? `${asistente.NOMBRES} ${asistente.APELLIDOS}` : ''}
                                                                                        </label>
                                                                                        :
                                                                                        <Fragment>
                                                                                            <input id={`input_nombres_${asistente.ID}`} className="form-control" type="text" defaultValue={asistente.NOMBRES} />
                                                                                            <input id={`input_apellidos_${asistente.ID}`} className="form-control" type="text" defaultValue={asistente.APELLIDOS} />
                                                                                        </Fragment>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                        Name in badge: &nbsp;
                                                                                    </label>
                                                                                    {!editarAsistentes ?
                                                                                        <label id={`label_badge_${asistente.ID}`} className="col-form-label text-left">
                                                                                            {asistente.NAME_IN_BADGE ? `${asistente.NAME_IN_BADGE}` : ''}
                                                                                        </label>
                                                                                        :
                                                                                        <Fragment>
                                                                                            <input id={`input_badge_${asistente.ID}`} className="form-control" type="text" defaultValue={asistente.NAME_IN_BADGE} />
                                                                                        </Fragment>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                        Email: &nbsp;
                                                                                    </label>

                                                                                    {!editarAsistentes ?
                                                                                        <label id={`label_correo_${asistente.ID}`} className="col-form-label text-left">
                                                                                            {asistente.CORREO || ''}
                                                                                        </label>
                                                                                        :
                                                                                        <input id={`input_correo_${asistente.ID}`} className="form-control" type="text" defaultValue={asistente.CORREO || ''} />
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                        Phone: &nbsp;
                                                                                    </label>

                                                                                    {!editarAsistentes ?
                                                                                        <label id={`label_whatsapp_${asistente.ID}`} className="col-form-label text-left">
                                                                                            {asistente.WHATSAPP ? `+${asistente.WHATSAPP_CODIGO} ${asistente.WHATSAPP}` : ''}
                                                                                        </label>
                                                                                        :
                                                                                        <input id={`input_whatsapp_${asistente.ID}`} className="form-control" type="text" defaultValue={asistente.WHATSAPP || ''} />
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                        Passport: &nbsp;
                                                                                    </label>
                                                                                    <label id={`label_passport_${asistente.ID}`} className="col-form-label text-left">
                                                                                        {asistente.PASSPORT || ''}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                        Company: &nbsp;
                                                                                    </label>

                                                                                    {!editarAsistentes ?
                                                                                        <label id={`label_company_${asistente.ID}`} className="col-form-label text-left">
                                                                                        {asistente.COMPANY || ''}
                                                                                        </label>
                                                                                        :
                                                                                        <input id={`input_company_${asistente.ID}`} className="form-control" type="text" defaultValue={asistente.COMPANY || ''} />
                                                                                    }
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                        Position: &nbsp;
                                                                                    </label>      

                                                                                    {!editarAsistentes ?
                                                                                        <label id={`label_position_${asistente.ID}`} className="col-form-label text-left">
                                                                                        {asistente.POSITION || ''}
                                                                                        </label>
                                                                                        :
                                                                                        <input id={`input_position_${asistente.ID}`} className="form-control" type="text" defaultValue={asistente.POSITION || ''} />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                    Food allergies: &nbsp;
                                                                                    </label>

                                                                                    <label id={`label_food_${asistente.ID}`} className="col-form-label text-left">
                                                                                        {asistente.FOOD || 'None'}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                    Dietary restrictions &nbsp;
                                                                                    </label>

                                                                                    <label id={`label_diet_${asistente.ID}`} className="col-form-label text-left">
                                                                                        {asistente.DIET || 'None'}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <div className="info-container">
                                                                                    <label className="col-form-label text-left font-weight-bold">
                                                                                    Password APP &nbsp;
                                                                                    </label>

                                                                                    <label id={`label_password_${asistente.ID}`} className="col-form-label text-left">
                                                                                        {asistente.CONTRA || '-'}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            {asistente.CENA_BIENVENIDA && asistente.CENA_BIENVENIDA !== '' ?
                                                                                <div className="col-12">
                                                                                    <div className="info-container">
                                                                                        <label className="col-form-label text-left font-weight-bold">
                                                                                            Cena bienvenida, mesa y silla: &nbsp;
                                                                                        </label>
                                                                                        <label className="col-form-label text-left">
                                                                                            {asistente.CENA_BIENVENIDA}
                                                                                        </label>
                                                                                    </div>
                                                                                </div> : null
                                                                            }
                                                                            {asistente.CENA_CLAUSURA && asistente.CENA_CLAUSURA !== '' ?
                                                                                <div className="col-12">
                                                                                    <div className="info-container">
                                                                                        <label className="col-form-label text-left font-weight-bold">
                                                                                            Cena clausura, mesa y silla: &nbsp;
                                                                                        </label>
                                                                                        <label className="col-form-label text-left">
                                                                                            {asistente.CENA_CLAUSURA}
                                                                                        </label>
                                                                                    </div>
                                                                                </div> : null
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            </Fragment>
                                                        </div>
                                                    )
                                                }


                                            </div>
                                        </form>
                                    </div>
                                    {
                                        !editarAsistentes ?
                                            <div className="col-12 mx-auto text-center">
                                                <button id="btn_actualizar_asistentes" className="btn btn-primary" type="button" onClick={() => {
                                                    iniciarEditarAsistentes();
                                                }}>Editar asistentes</button>
                                            </div>
                                            :
                                            <div className="col-12 mx-auto text-center">
                                                <button id="btn_cancelar_asistentes" className="btn btn-muted" type="button" onClick={() => {
                                                    setEditarAsistentes(false);
                                                }}>Dejar de editar</button>
                                                <button id="btn_guardar_asistentes" disabled={actualizandoAsistentes} className="btn btn-primary" type="button" onClick={() => {
                                                    actualizarDatosAsistentes();
                                                }}>Guardar cambios</button>
                                            </div>
                                    }
                                </div>
                                <br />
                            </div>
                        </div>
                    </AccordionItem>
                </Accordion>
                <div style={{ height: 50 }}></div>
            </div>
        </Fragment>
    </div>

}

export default Detalle;