import React, { Component, useState, useEffect, Fragment } from "react";
import Countup from "react-countup";
import { Doughnut, Line, Bar } from "react-chartjs-2";
import { Chart } from "react-google-charts";
import ChartistGraph from "react-chartist";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import "react-datepicker/dist/react-datepicker.css";


import {
    taskChartData,
    taskChartOptions,
    barChartData,
    barChartOptions,
    projectsmall,
    projectsmall1,
    carnetpagado,
    carnetpagadoopts,
} from "./chart-data";
import { MoreHorizontal, Users, UserCheck, UserX, AlignRight } from "react-feather";

const primary = localStorage.getItem("primary_color") || "#4466f2";
const secondary = localStorage.getItem("secondary_color") || "#1ea6ec";
//const [user, setUser] = useState({})

const HomeComponent1 = (props) => {
    const [selectFechaInicio, setSelectFechaInicio] = useState(null)
    const [selectFechaFin, setSelectFechaFin] = useState(null)
    const [fechaInicio, setFechaInicio] = useState(new Date)
    const [fechaFin, setfechaFin] = useState(new Date)
    const [fecha, setFecha] = useState(new Date())
    const [dropdownOpen, setOpen] = useState(false);
    const [seleccion, setSeleccion] = useState('Carnet')
    const [producto, setProducto] = useState(1)

    const [preregistros, setPreregistros] = useState(0)
    const [pendientes, setPendientes] = useState(0)
    const [pagados, setPagados] = useState(0)
    const [montoTotal, setMontoTotal] = useState(0)
    const [montoTotalNeto, setMontoTotalNeto] = useState(0)
    const [cortesias, setCortesias] = useState(0)
    const [pagadosCortesia, setPagadosCortesia] = useState(0)
    const [pagadosHombres, setPagadosHombres] = useState(0)
    const [pagadosMujeres, setPagadosMujeres] = useState(0)
    const [tablaEstados, setTablaEstados] = useState([])
    const [datosGrafica, setDatosGrafica] = useState([])
    const [productos, setProductos] = useState([])
    const [hoteles, setHoteles] = useState({})
    // const [palacioSencillo, setPalacioSencillo] = useState(0)
    // const [palacioDoble, setPalacioDoble] = useState(0)
    // const [palacioPendiente, setPalacioPendiente] = useState(0)
    // const [princessSencillo, setPrincessSencillo] = useState(0)
    // const [princessDoble, setPrincessDoble] = useState(0)
    // const [princessPendiente, setPrincessPendiente] = useState(0)

    let date1 = null
    let date2 = null

    useEffect(() => {
        setData(props)
    }, [props]);

    const setData = (params) => {
        setPreregistros(params.preregistros)
        setPendientes(params.pendientes)
        setPagados(params.pagados)
        setMontoTotal(params.montoTotal)
        setMontoTotalNeto(params.montoTotalNeto)
        setCortesias(params.cortesias)
        setPagadosCortesia(params.pagadosCortesia)
        setPagadosHombres(params.pagadosHombres)
        setPagadosMujeres(params.pagadosMujeres)
        setTablaEstados(params.tablaEstados)
        setDatosGrafica(params.grafica)
        setProductos(params.productos)
        setHoteles(params.hoteles)
        // setPalacioSencillo(params.palacioSencillo)
        // setPalacioDoble(params.palacioDoble)
        // setPalacioPendiente(params.palacioPendiente)
        // setPrincessSencillo(params.princessSencillo)
        // setPrincessDoble(params.princessDoble)
        // setPrincessPendiente(params.princessPendiente)
    }

    const toggle = () => setOpen(!dropdownOpen);

    const inicioFecha = (fech) => {
        return (
            <div className="input-group">
                <p>De:</p><DatePicker className="form-control digits" selected={fech} onChange={(date) => {
                    date1 = date
                    if (date1 && date2) diasGrafica(0, date1, date2, 2)
                    setSelectFechaInicio(inicioFecha(date))
                }} />
            </div>
        )
    }

    const finFecha = (fech) => {
        return (
            <div className="input-group">
                <p>Hasta:</p><DatePicker className="form-control digits" selected={fech} onChange={(date) => {
                    date2 = date
                    if (date1 && date2) diasGrafica(0, date1, date2, 2)
                    console.log(date1, date2);
                    setSelectFechaFin(finFecha(date))
                }} />
            </div>
        )
    }

    const obtenerDatos = async (producto) => {
        let response = await fetch('/rt_president', {
            method: 'POST',
            body: JSON.stringify({ evento: 4, producto, dias: 30 }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            setData(res)
        }
        catch (err) {
            console.log(err);
        }
    }

    const diasGrafica = async (dias, fechIn, fechFin, opcion) => {
        let response = await fetch('/rt_president', {
            method: 'PUT',
            body: JSON.stringify({ evento: 4, producto: producto, dias, fechIn, fechFin, opcion }),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            console.log(res);
            setDatosGrafica(res.grafica)
        }
        catch (err) {
            console.log(err);
        }
    }

    const estadosTabla = tablaEstados.map((elements, index) =>
        <tr key={`td_estados_tabla_${index}`}>
            <td>{elements.NOMBRE_CES}</td>
            <td className="text-center">{elements.ASISTENTES_PREREGISTRADOS}</td>
            <td className="text-center">{elements.ASISTENTES_PAGADOS}</td>
            <td className="text-center">{elements.ASISTENTES_CORTESIA}</td>
        </tr>
    )

    const product = productos.map((element, index) =>
        <DropdownItem key={`dropdown_item_product_${index}`} onClick={() => {
            setSeleccion(element.NOMBRE_TPS)
            setProducto(element.ID_TPS)
            obtenerDatos(element.ID_TPS)
        }}>{element.NOMBRE_TPS}</DropdownItem>
    )

    const excelRegistros = async (prod) => {
        fetch('/rt_president?p=' + prod, {
            method: 'GET',
        })
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (res) {
                if (res) {
                    let blob = new Blob([res], { type: "application/xlsx" });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    let fileName = seleccion + '.xlsx';
                    link.download = fileName;
                    link.click();
                }
            }).catch(function (err) {
                console.log('Error al cargar registros:', err);
            })
    }

    return (
        <Fragment>
            <div className="col-sm-12 mt-5">
                <div className="row">
                    <div className="col-sm-12 mb-2 mt-3">
                        {/* <Breadcrumb title="President" parent="Inicio" /> */}
                    </div>
                </div>

                <form id="form_president" className="form theme-form">
                    <div className="row">
                        <div className="col-md-12 card">
                            <div className="row mt-3">
                                <div className="col-12">
                                    <h5 className="mb-3">President</h5>
                                </div>
                                <div className="col-6">
                                    <div className="">
                                        <div className="carnet-body">
                                            <div className="form-group">
                                                <label style={{ fontWeight: 600 }}>
                                                    Name
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="">
                                        <div className="carnet-body">
                                            <div className="form-group">
                                                <label style={{ fontWeight: 600 }}>
                                                    Position
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="">
                                        <div className="carnet-body">
                                            <div className="form-group">
                                                <label style={{ fontWeight: 600 }}>
                                                    Image
                                                </label>
                                                <input className="form-control" type="file" id="formFile"></input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="">
                                        <div className="carnet-body">
                                            <div className="form-group">
                                                <label style={{ fontWeight: 600 }}>
                                                Resume
                                                </label>
                                                <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button type="button" className="btn btn-success" >
                                        Save changes
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Fragment >
    );
}

// class HomeComponent extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             open: false,
//             p: props,
//             m: 'mensaje'
//         };
//         console.log('Los props', props);

//     }

//     componentWillUnmount() {
//         document.removeEventListener("mousedown", this.handleClickOutside);
//     }
//     componentDidMount() {
//         document.addEventListener("mousedown", this.handleClickOutside);
//     }
//     handleClickOutside = (event) => {
//         this.setState({
//             open: false,
//         });
//     };
//     handleButtonClick = () => {
//         this.setState((state) => {
//             return {
//                 open: !state.open,
//             };
//         });
//     };
// }

export default HomeComponent1;