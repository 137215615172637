import React, { Fragment, useState, useEffect } from "react";
// import Tablereact from '../common/tabulator/tablereact'
import Select from "react-select";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Breadcrumb from "../common/breadcrumb";

import ContextoSesion from '../../context/ContextoSesion';

import Paginador from "../../componentes/paginador";

// import DropDown from './dropdown'

// import {
//     CheckCircle, XSquare
// } from 'react-feather';

const Prueba = () => {
	
	const {sesion} = React.useContext(ContextoSesion);

	const params = useParams();

	console.log('params desde registro convencio', params);

	const [props, setPrevProps] = useState(params)

	const [catalogos, setCatalogos] = useState({
		evento: {},
		estatus: [],
		tipos: [],
		estados: []
	})

	const [tabla, setTabla] = useState({
		registros: [],
		pagina: 1,
		ultima_pagina: 1,
		paginas: [],
		cantidad_resultados: 10,
		busqueda: '',
		orden_activo: 'id',
		orden: true,
		estatus: [],
		estados: [],
		tipos: [],
	})

	const [primeraCarga, setPrimeraCarga] = useState(true)

	const history = useNavigate()

	let timeout;
	let busqueda_pendiente = '';

	const elemSelect = [
		{ value: "10", label: "10" },
		{ value: "20", label: "20" },
		{ value: "30", label: "30" },
		{ value: "40", label: "40" },
	];

	const clasesTipos = {
		"0": "pendiente",
		"1": "transferencia",
		"2": "paypal",
		"3": "link",
		"4": "terminal",
		"5": "pendiente",
		"6": "terminal",
	}

	const clasesEstatus = {
		"0": "cancelado",
		"1": "pendiente",
		"2": "pagado",
		"3": "revisar",
		"4": "pendiente",
		"5": "cortesia",
	}

	const search = useLocation().search;
	const search_params = new URLSearchParams(search);
	const ruta = useLocation().pathname;
	
	useEffect(() => {

		let s_estatus			 = search_params.get('estatus'),
			s_estados			 = search_params.get('estados'),
			s_tipos				 = search_params.get('tipos'),
			s_busqueda		 	 = search_params.get('busqueda'),
			s_cantidad_resultados= search_params.get('cantidad_resultados'),
			s_pagina			 = search_params.get('pagina'),
			s_orden_activo		 = search_params.get('orden_activo'),
			s_orden				 = search_params.get('orden');

		s_estatus				= s_estatus	? JSON.parse(s_estatus)	: [];
		s_estados				= s_estados	? JSON.parse(s_estados)	: [];
		s_tipos 				= s_tipos	? JSON.parse(s_tipos)		: [];
		s_busqueda 				= s_busqueda || '';
		s_cantidad_resultados	= s_cantidad_resultados || 10;
		s_pagina		 		= parseInt(s_pagina) || 1;
		s_orden_activo			= s_orden_activo || 'id';
		s_orden 				= s_orden || true;

		setPrimeraCarga(false);
			setTabla((s) => ({...s,pagina: s_pagina,cantidad_resultados: s_cantidad_resultados,busqueda: s_busqueda,orden_activo: s_orden_activo,orden: s_orden,estatus: s_estatus,estados: s_estados,tipos: s_tipos
		}));

		fetch('../../rt_convencion_catalogos', {
			method: 'POST',
			body: JSON.stringify({evento: params.evento}),
			headers: {'Content-Type': 'application/json'}
		}).then(function(res) { return res.json(); })
		.then(function(res) {
			console.log('use effect registro convencion',res);
			if(res.status == 'OK'){
				let estatus = res.estatus.map(estatus => {
					return {value: estatus.ESTATUS, label: estatus.NOMBRE}
				});
				// setEstatusConvencion(estatus);
				let tipos = res.tipos.map(tipo => {
					return {value: tipo.TIPO, label: tipo.NOMBRE}
				});
				// setTiposConvencion(tipos);
				let estados = res.estados.map(tipo => {
					return {value: tipo.CLAVE, label: tipo.NOMBRE}
				});
				// setTiposConvencion(estados);
				setCatalogos({evento: res.evento, estatus, tipos, estados});
			}
		}).catch(function(err){
			console.log('Error al cargar catálogos:', err);
		})
	}, [params.evento])

	useEffect(() => {
		if(primeraCarga)
			return;

		if(params.evento != params.evento || params.tps != params.tps){
			limpiarFiltro();
			setPrevProps(props);
			return;
		}

		console.log('Cargar info tabla');
		setTabla((s)=>({...s, registros: false}))
		let isS = true;
		fetch('../../rt_convencion', {
			method: 'POST',
			body: JSON.stringify({
				estados: Array.isArray(tabla.estados) ? tabla.estados.map(val => `'${val.value}'`) : [],
				estatus: Array.isArray(tabla.estatus) ? tabla.estatus.map(val => val.value) : [],
				tipos: Array.isArray(tabla.tipos) ? tabla.tipos.map(val => val.value) : [],
				busqueda: tabla.busqueda,
				cantidad_resultados: tabla.cantidad_resultados,
				pagina: tabla.pagina,
				orden_activo: tabla.orden_activo,
				orden: tabla.orden,
				evento: params.evento,
				tps: params.tps
			}),
			headers: { 'Content-Type': 'application/json' }
		})
		.then(function (res) { return res.json(); })
		.then(function (res) {
			console.log(res);
			if (res.status == 'OK') {

				let url = ruta;
				let params = ``;

				if(tabla.estados && tabla.estados.length > 0) params += `estados=${JSON.stringify(tabla.estados)}&`;
				if(tabla.estatus && tabla.estatus.length > 0) params += `estatus=${JSON.stringify(tabla.estatus)}&`;
				if(tabla.tipos && tabla.tipos.length > 0) params += `tipos=${JSON.stringify(tabla.tipos)}&`;
				if(tabla.busqueda && tabla.busqueda != '') params += `busqueda=${tabla.busqueda}&`;
				if(tabla.cantidad_resultados && tabla.cantidad_resultados != 10) params += `cantidad_resultados=${tabla.cantidad_resultados}&`;
				if(tabla.pagina &&  tabla.pagina != 1) params += `pagina=${tabla.pagina}&`;
				if(tabla.orden_activo && tabla.orden_activo != 'id') params += `orden_activo=${tabla.orden_activo}&`;
				if(tabla.orden && tabla.orden != true) params += `orden=${false}&`;
			
				if(params.length > 0) url = `${ruta}?${params.substring(0, params.length-1)}`

				window.history.replaceState(null, "Admin", url)

				let total_resultados = res.result && res.result[0] ? res.result[0].RESULTADOS : 0;
				let obj_paginador = paginacion(tabla.pagina, total_resultados, tabla.cantidad_resultados);
				console.log('tabla.pagina, total_resultados, tabla.cantidad_resultados', tabla.pagina, total_resultados, tabla.cantidad_resultados);
					
				setTabla((s)=>({...s, registros: res.result, paginas: obj_paginador.paginas, ultima_pagina: obj_paginador.total_paginas}))
			}
		}).catch(function (err) {
			console.log('Error al cargar registros:', err);
		})

		return () => isS = false
	}, [tabla.estatus, tabla.estados, tabla.tipos, tabla.pagina, tabla.busqueda, tabla.orden_activo, tabla.orden, params.evento, params.tps])

	function construirElementos() {
		return tabla.registros.map((v, i) => 
			<tr key={`fila_registros${i}`}>
				<td scope="row">
					<div>
						<div style={{ fontSize: 16 }}>
							<strong>{v.FOLIO}</strong>
						</div>
						<small>{v.FECHA_ALTA}</small>
					</div>
				</td>
				<td>
					<div>
						<strong>{v.NOMBRE}</strong>
						<br /> <small>{v.CORREO}</small>
					</div>
				</td>
				<td>
					<div>
						<strong>{v.NOMBRE_INSTITUCION}</strong>
						<br />
						<small>{v.TIPO_INSTITUCION}</small>
					</div>
				</td>
				{/* {
				params.tps !== '0' ?
				<td>
					<div>
						<strong>
							{v.RAZON_SOCIAL}
						</strong>
						<br />
						<small>RFC: {v.RFC}</small>
					</div>
				</td>: null
				} */}
				{
				params.tps === '0' ?
				<td>
					<div>
						<strong>
							{v.TIPO_CARNET}
						</strong>
						{/* <br />
						<small>RFC: {v.RFC}</small> */}
					</div>
				</td>: null
				}
				{
				params.tps !== '0' ?
				<td>
					<div
						className={`alert alert-light p-2 m-0 rounded ${clasesTipos[v.PASARELA_PAGO_VALOR]}`}
						role="alert"
					>
						{v.PASARELA_PAGO}
					</div>
				</td>: null
				}
				{
				params.tps !== '0' ?
				<td>
					<div
						className={`alert alert-light p-2 m-0 rounded ${clasesEstatus[v.ESTATUS_PAGO_VALOR]}`}
						role="alert"
					>
						{v.ESTATUS_PAGO}
					</div>
				</td>: null
				}
				<td className="detalle-col text-center">
					<div className="row">
						<div className="col-6">
							<button type="button" className="btn btn-detalle rounded-pill" onClick={() => {
								console.log('redirigir a', `/detalle-convencion/${params.evento}/${v.TPS}/${v.FOLIO}`);
								history(`/detalle-convencion/${params.evento}/${v.TPS}/${v.FOLIO}`);
							}}>
								Detail
							</button>
						</div>
						{
						params.tps === '0' ?
						<div className="col-6">
							<button type="button" className="btn btn-success rounded-pill" onClick={() => {
								console.log('redirigir a', `/detalle-convencion/${params.evento}/${params.tps}/${v.FOLIO}`);
								history(`/carnet/editar/${v.ID}`);
							}}>
								Editar
							</button>
						</div>: null
						}
					</div>
				</td>
			</tr>
		)
	}

	function construirElementosCargando() {
		let arreglo = [], i;
		for(i = 0; i < tabla.cantidad_resultados; i++){
			arreglo.push(
			<tr key={`fila_cargando${i}`}>
				<td scope="row">
					<div>
						<div style={{ fontSize: 16 }}>
							<div className="skeleton skeleton-text skeleton-text__body"></div>
						</div>
						<div className="skeleton skeleton-text skeleton-footer"></div>
					</div>
				</td>
				<td>
					<div>
						<div className="skeleton skeleton-text skeleton-footer"></div>{" "}
						<div className="skeleton skeleton-text skeleton-text__body"></div>
					</div>
				</td>
				<td>
					<div>
						<div className="skeleton skeleton-text skeleton-footer"></div>{" "}
						<div className="skeleton skeleton-text skeleton-text__body"></div>
					</div>
				</td>
				<td>
					<div>
						<div className="skeleton skeleton-text skeleton-text__body"></div>
						<div className="skeleton skeleton-text skeleton-text__body"></div>
					</div>
				</td>
				<td>
					<div className="skeleton skeleton-text skeleton-badge"></div>
				</td>
				<td>
					<div className="skeleton skeleton-text skeleton-badge"></div>
				</td>
				<td className="detalle-col">
					<div className="skeleton skeleton-text skeleton-badge"></div>
				</td>
			</tr>
			)
		}

		return arreglo;
	}

	function evaluarEscribiendo(e) {
		console.log('Evaluar escribiendo', e.target.value);
		if(timeout){
			clearTimeout(timeout)
		}

		busqueda_pendiente = e.target.value;

		timeout = setTimeout(() => {
			setTabla((s) => ({...s, busqueda: busqueda_pendiente}))
		}, 300)
	}

	function selectTipo(value) {
		setTabla((s) => ({...s, tipos: value, pagina: 1}))
	}

	function selectEstatus(value) {
		setTabla((s) => ({...s, estatus: value, pagina: 1}))
	}

	function selectEstados(value) {
		setTabla((s) => ({...s, estados: value, pagina: 1}))
	}

	function selectCantidadResultados(value) {
		tabla.cantidad_resultados = value.value;
	}

	function paginacion (pag, n_resultados, cantidad_pagina) {
		pag = parseInt(pag)
		var cantidad = cantidad_pagina, array_paginas = []
		var i, fin, total = Math.ceil(n_resultados/cantidad), suma = true, content = ''
	
		if(pag - 2 < 1){
			i = 1
			fin = 5
		}else{
			i = pag - 2
			fin = pag + 2
		}
	
		if(fin > total){
			fin = total
		}
		if((fin - i) < 4){
			while((fin - i) < 4 && suma){
				suma = false
				if(i > 1){
					suma = true
					i--
				}
				if((fin - i) < 4 && fin < total){
					suma = true
					fin++
				}
			}
		}
	
		for(i; i <= fin; i++){
			array_paginas.push(i)
		}
	
		return {paginas: array_paginas, total_paginas: total}
	}

	function descargarRegistros () {
		fetch('../../rt_convencion/descargar', {
			method: 'POST',
			body: JSON.stringify({
				estados: Array.isArray(tabla.estados) ? tabla.estados.map(val => `'${val.value}'`) : [],
				estatus: Array.isArray(tabla.estatus) ? tabla.estatus.map(val => val.value) : [],
				tipos: Array.isArray(tabla.tipos) ? tabla.tipos.map(val => val.value) : [],
				busqueda: tabla.busqueda,
				cantidad_resultados: tabla.registros[0] ? tabla.registros[0].RESULTADOS : 0,
				pagina: 1,
				orden_activo: tabla.orden_activo,
				orden: tabla.orden,
				evento: params.evento,
				tps: params.tps
			}),
			headers: { 'Content-Type': 'application/json' }
		})
		.then(function (res) { return res.arrayBuffer(); })
		.then(function (res) {
			if (res) {
				let blob = new Blob([res], {type: "application/xlsx"});
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				let fileName = 'registros.xlsx';
				link.download = fileName;
				link.click();
			}
		}).catch(function (err) {
			console.log('Error al cargar registros:', err);
		})
	}

	function descargarRegistrosAsistentes () {
		fetch('../../rt_convencion/asistentes/descargar', {
			method: 'POST',
			body: JSON.stringify({
				estados: Array.isArray(tabla.estados) ? tabla.estados.map(val => `'${val.value}'`) : [],
				estatus: Array.isArray(tabla.estatus) ? tabla.estatus.map(val => val.value) : [],
				tipos: Array.isArray(tabla.tipos) ? tabla.tipos.map(val => val.value) : [],
				busqueda: tabla.busqueda,
				cantidad_resultados: tabla.registros[0] ? tabla.registros[0].RESULTADOS : 0,
				pagina: 1,
				orden_activo: tabla.orden_activo,
				orden: tabla.orden,
				evento: params.evento,
				tps: params.tps
			}),
			headers: { 'Content-Type': 'application/json' }
		})
		.then(function (res) { return res.arrayBuffer(); })
		.then(function (res) {
			if (res) {
				let blob = new Blob([res], {type: "application/xlsx"});
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				let fileName = 'registros.xlsx';
				link.download = fileName;
				link.click();
			}
		}).catch(function (err) {
			console.log('Error al cargar registros:', err);
		})
	}

	function limpiarFiltro() {
		document.getElementById('input_buscador').value = '';
		setTabla((s) => ({...s, 
			registros: [],
			pagina: 1,
			ultima_pagina: 1,
			paginas: [],
			cantidad_resultados: 10,
			busqueda: '',
			orden_activo: 'id',
			orden: true,
			estatus: [],
			tipos: [],
		}))
	}

	return (
		<Fragment>
			<div className="col-sm-12 mt-5 pt-5">
				<div className="row d-none">
					<div className="col-sm-12 mb-2 mt-3">
						<Breadcrumb title={catalogos.evento.NOMBRE} parent="Home" />
					</div>
					<div className="col-xl-6 mb-2 mt-3">
						<label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
							SEARCH:
						</label>
						<input
							id="input_buscador"
							type="text"
							className="form-control"
							name="buscar"
							aria-label=""
							aria-describedby="basic-addon1"
							onKeyUp={evaluarEscribiendo}
						/>
					</div>
				</div>

				<div className="row d-none">
					<div className=" col-md-3 col-sm-6 col-12 mt-2 mb-3">
						<Select
							options={catalogos.tipos}
							isMulti
							placeholder={"Payment type"}
							value={tabla.tipos && tabla.tipos.length === 0 ? null: tabla.tipos}
							onChange={selectTipo}
						/>
					</div>
					<div className=" col-md-3 col-sm-6 col-12 mt-2 mb-3">
						<Select
							options={catalogos.estatus}
							isMulti
							placeholder={"Payment status"}
							value={tabla.estatus && tabla.estatus.length === 0 ? null: tabla.estatus}
							onChange={selectEstatus}
						/>
					</div>
					{/* <div className=" col-md-3 col-sm-6 col-12 mt-2 mb-3">
						<Select
							options={catalogos.estados}
							isMulti
							placeholder={"Estados"}
							value={tabla.estados && tabla.estados.length === 0 ? null: tabla.estados}
							onChange={selectEstados}
						/>
					</div> */}

				</div>
				
				<div className="card mt-2 d-none">
					<div className="table-responsive prueba">
						<table className="table table-sticky">
							<thead>
								<tr>
									<th scope="col">
										<div className="tab-header">
											<span>TITLE</span>{" "}
											<div className="filter-btn" onClick={() => {
												let mismo_filtro = tabla.orden_activo == 'id';
												setTabla(s => ({...s, orden_activo: 'id', orden: mismo_filtro ? !tabla.orden : false}))
											}}>
												<i id="filterAD" className={tabla.orden_activo == "id" && !tabla.orden ? "fa fa-sort-amount-asc": "fa fa-sort-amount-desc"}></i>
											</div>
										</div>
									</th>
									<th scope="col">
										<div className="tab-header justify-content-center">
											<span className="mb-32">DETAIL</span>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{tabla.registros ?
									construirElementos()
									:
									construirElementosCargando()
								}
							</tbody>
						</table>
					</div>
				</div>

				<div className="row d-none">
					<div className="col-12 selectnum d-flex align-items-center justify-content-end flex-wrap">
						<div className="select-number d-flex align-items-center mr-3 mb-3">
							<label className="mb-0 mr-2">Show:</label>

							<Select
								style={{ width: 40 }}
								options={elemSelect}
								placeholder="10"
								onChange={selectCantidadResultados}
								menuPlacement="auto"
							></Select>
						</div>
						<div className="pagin">
							<Paginador 
							pagina={tabla.pagina}
							paginas={tabla.paginas}
							ultima_pagina={tabla.ultima_pagina}
							cargarDatos={(pag) => setTabla((s) => ({...s, pagina: pag}))}
							/>
						</div>
					</div>
				</div>
				
				<div className="row pt-3" style={{backgroundColor: '#ffffff'}}>
					<div className="col-12">
                    	<h3 className="mb-3">Registration tickets</h3>
                    </div>
					<div className="col-12">
						<h4 className="w-50">Available tickets</h4>
						<button className="btnAddTicket" data-bs-toggle="modal" data-bs-target="#ticketsModal">Add new Ticket</button>
						<div className="row">
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>Full AGM 4 days program (Member)</h6>
									<p>$3,000.00</p>
									<p><strong>Ticket info:</strong></p>
									<p class="mb-2">This type of registration includes:</p>
									<ul class="list-default">
                                        <li>Participation in all activities and events of the 64th AGM.</li>
                                        <li>Committee Meetings ( only committee members)</li>
                                        <li>Welcome reception</li>
                                        <li>Award Ceremony and Award Luncheon</li>
                                        <li>Networking activities on Tuesday May 7, afternoon - city tour</li>
                                        <li>Casual dinner</li>
                                        <li>Committee Chair Luncheon</li>
                                        <li>Annual Institute General BOD Meeting (only members)</li>
                                        <li>Gala Dinner</li>
                                        <li>Speaker program - two sessions</li>
                                        <li>Farewell lunch</li>
                                    </ul>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>2 day participation program (Member)</h6>
									<p>$2,200.00</p>
									<p><strong>Ticket info:</strong></p>
									<p class="mb-2">This type of registration includes:</p>
									<ul>
										<li>Committee meetings ( only committee members)</li>
                                        <li>Award ceremony</li>
                                        <li>Award luncheon</li>
                                        <li>Networking activity - city tour</li>
                                        <li>Casual dinner</li>
                                        <li>Speaker program</li>
                                        <li>Committee chair luncheon</li>
                                        <li>Annual Institute General BOD meeting (only members)</li>
                                        <li>Gala dinner</li>
                                    </ul>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>1 day participation program (Member)</h6>
									<p>$1,200.00</p>
									<p><strong>Ticket info:</strong></p>
									<div class="info-carnet">
                                        <p class="mb-2">This type of registration includes: <small><i>(All activities on Tuesday, May 7, or Wednesday, May 8.)</i></small></p>
                                        <small>Tuesday:</small>
                                        <ul class="list-default">
                                            <li>Committee meetings ( only committee members)</li>
                                            <li>Award ceremony</li>
                                            <li>Award luncheon</li>
                                            <li>Networking activity - city tour</li>
                                            <li>Casual dinner</li>
                                        </ul>
                                        <small>Wednesday:</small>
                                        <ul class="list-default">
                                            <li>Speaker program</li>
                                            <li>Committee chair luncheon</li>
                                            <li>Annual Institute General BOD meeting (only members)</li>
                                            <li>Gala dinner</li>
                                        </ul>
                                    </div>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>Full AGM 4 days program (Non-Member)</h6>
									<p>$6,000.00</p>
									<p><strong>Ticket info:</strong></p>
									<div class="info-carnet">
                                        <p class="mb-2">This type of registration includes:</p>
                                        <ul class="list-default">
                                            <li>Participation in all activities and events of the 64th AGM</li>
                                            <li>Welcome reception</li>
                                            <li>Award Ceremony and Award Luncheon</li>
                                            <li>Networking activities on Tuesday May 7, afternoon - city tour</li>
                                            <li>Casual dinner</li>
                                            <li>Committee Chair Luncheon</li>
                                            <li>Gala Dinner</li>
                                            <li>Speaker program - two sessions</li>
                                            <li>Farewell lunch</li>
                                        </ul>
                                    </div>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>Attendee's Companion</h6>
									<p>Fee varies</p>
									<p><strong>Ticket info:</strong></p>
									<div class="info-carnet">
                                        <p>Welcome reception – <strong>180 USD</strong></p>
                                        <p>Networking - City tour and casual dinner – <strong>350 USD</strong></p>
                                        <p>Award Ceremony and luncheon – ​<strong>180 USD</strong></p>
                                        <p>Committee Chair Luncheon – <strong>180 USD</strong></p>
                                        <p>Gala dinner – <strong>400 USD</strong></p>
                                        <p>Farewell Luncheon - <strong>180 USD</strong></p>
                                    </div>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>Speaker</h6>
									<p>No Fee</p>
									<p><strong>Ticket info:</strong></p>
									<div class="info-carnet">
                                        <p class="mb-2">This type of registration includes:</p>
                                        <ul class="list-default">
                                            <li>Participation in all activities and events of the 64th AGM</li>
                                            <li>Committee Meetings ( only committee members)</li>
                                            <li>Welcome reception</li>
                                            <li>Award Ceremony and Award Luncheon</li>
                                            <li>Networking activities on Tuesday, May 7, afternoon - city tour</li>
                                            <li>Casual dinner</li>
                                            <li>Committee Chair Luncheon</li>
                                            <li>Annual Institute General BOD Meeting (only members)</li>
                                            <li>Gala Dinner</li>
                                            <li>Speaker program - two sessions</li>
                                            <li>Farewell lunch</li>
                                        </ul>
                                    </div>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>Award</h6>
									<p>No Fee</p>
									<p><strong>Ticket info:</strong></p>
									<div class="info-carnet">
                                        <p class="mb-2">This type of registration includes:</p>
                                        <ul class="list-default">
                                            <li>Participation in all activities and events of the 64th AGM</li>
                                            <li>Committee Meetings ( only committee members)</li>
                                            <li>Welcome reception</li>
                                            <li>Award Ceremony and Award Luncheon</li>
                                            <li>Networking activities on Tuesday, May 7, afternoon - city tour</li>
                                            <li>Casual dinner</li>
                                            <li>Committee Chair Luncheon</li>
                                            <li>Annual Institute General BOD Meeting (only members)</li>
                                            <li>Gala Dinner</li>
                                            <li>Speaker program - two sessions</li>
                                            <li>Farewell lunch</li>
                                        </ul>
                                    </div>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>Press</h6>
									<p>No Fee</p>
									<p><strong>Ticket info:</strong></p>
									<div class="info-carnet">
                                        <p class="mb-2">This type of registration includes:</p>
                                        <ul class="list-default">
                                            <li>Participation in all activities and events of the 64th AGM</li>
                                            <li>Committee Meetings ( only committee members)</li>
                                            <li>Welcome reception</li>
                                            <li>Award Ceremony and Award Luncheon</li>
                                            <li>Networking activities on Tuesday, May 7, afternoon - city tour</li>
                                            <li>Casual dinner</li>
                                            <li>Committee Chair Luncheon</li>
                                            <li>Annual Institute General BOD Meeting (only members)</li>
                                            <li>Gala Dinner</li>
                                            <li>Speaker program - two sessions</li>
                                            <li>Farewell lunch</li>
                                        </ul>
                                    </div>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-6">
								<div className="card cardTicket">
									<h6>IISRP Staff</h6>
									<p>No Fee</p>
									<p><strong>Ticket info:</strong></p>
									<div class="info-carnet">
                                        <p class="mb-2">This type of registration includes:</p>
                                        <ul class="list-default">
                                            <li>Participation in all activities and events of the 64th AGM</li>
                                            <li>Committee Meetings ( only committee members)</li>
                                            <li>Welcome reception</li>
                                            <li>Award Ceremony and Award Luncheon</li>
                                            <li>Networking activities on Tuesday, May 7, afternoon - city tour</li>
                                            <li>Casual dinner</li>
                                            <li>Committee Chair Luncheon</li>
                                            <li>Annual Institute General BOD Meeting (only members)</li>
                                            <li>Gala Dinner</li>
                                            <li>Speaker program - two sessions</li>
                                            <li>Farewell lunch</li>
                                        </ul>
                                    </div>
									<div className="buttonsTicket">
										<button className="btnTicket edit"><i class="fa-regular fa-pen-to-square"></i> Edit Ticket</button>
										<button className="btnTicket delete"><i class="fa-regular fa-trash-can"></i> Delete Ticket</button>
										<button className="btnTicket hide"><i class="fa-regular fa-eye-slash"></i> Hide Ticket</button>
										<button className="btnTicket unhide"><i class="fa-regular fa-eye"></i> Unhide Ticket</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			//----Modal------//
			<div className="modal fade" id="ticketsModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="ticketsModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
					<div className="modal-content">
						<div className="modal-header">    
							<h1 className="modal-title fs-5" id="ticketsModalLabel">Add new ticket</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="form-group col-md-6">
									<label style={{ fontWeight: 600 }}>
									Ticket Name
									</label>
									<input className="form-control" type="text" placeholder=""/>
								</div>
								<div className="form-group col-md-6">
									<label style={{ fontWeight: 600 }}>
									Ticket Price
									</label>
									<input className="form-control" type="text" placeholder=""/>
								</div>
								<div className="form-group col-md-12">
									<label style={{ fontWeight: 600 }}>
									Ticket Info
									</label>
									<textarea className="form-control" placeholder=""></textarea>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="button" className="btn btnOrange">Add ticket</button>
						</div>
					</div>
				</div>
			</div>

		</Fragment>
	);
};

export default Prueba;


// return (
//     <Fragment>
//         <div className="col-sm-12 mt-5">
//             <div className="row">
//                 <div className="col-sm-12 mb-2 mt-3">
//                     {/* <Breadcrumb title="Registration" parent="Inicio" /> */}
//                 </div>
//             </div>

//             <form id="form_registration" className="form theme-form">
//                 <div className="row">
//                     <div className="col-md-12 card">
//                         <div className="row mt-3">
//                             <div className="col-12">
//                                 <h5 className="mb-3">Registration</h5>
//                             </div>
//                             <div className="col-6">
//                                 <div className="">
//                                     <div className="carnet-body">
//                                         <div className="form-group">
//                                             <label style={{ fontWeight: 600 }}>
//                                                 Title
//                                             </label>
//                                             <input
//                                                 className="form-control"
//                                                 type="text"
//                                                 placeholder=""
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-6">
//                                 <div className="">
//                                     <div className="carnet-body">
//                                         <div className="form-group">
//                                             <label style={{ fontWeight: 600 }}>
//                                                 Price
//                                             </label>
//                                             <input
//                                                 className="form-control"
//                                                 type="text"
//                                                 placeholder=""
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-6">
//                                 <div className="">
//                                     <div className="carnet-body">
//                                         <div className="form-group">
//                                             <label style={{ fontWeight: 600 }}>
//                                                 Description
//                                             </label>
//                                             <input
//                                                 className="form-control"
//                                                 type="text"
//                                                 placeholder=""
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-6">
//                                 <div className="">
//                                     <div className="carnet-body">
//                                         <div className="form-group">
//                                             <label style={{ fontWeight: 600 }}>
//                                                 Subtitle
//                                             </label>
//                                             <input
//                                                 className="form-control"
//                                                 type="text"
//                                                 placeholder=""
//                                             />
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-12">
//                                 <div className="">
//                                     <div className="carnet-body">
//                                         <div className="form-group">
//                                             <label style={{ fontWeight: 600 }}>
//                                             Includes
//                                             </label>
//                                             <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea2"></textarea>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="col-12">
//                                 <button type="button" className="btn btn-success" >
//                                     Save changes
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </form>
//         </div>
//     </Fragment >