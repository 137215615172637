import React, { Fragment } from 'react';

import Dashboard from './components/dashboard/dashboard';
import DatosDashboard from './components/datos_dashboard/dashboard';
import President from './components/president/president';
import DatosPresident from './components/datos_president/president';
import Registration from './components/registration/registration';
import DatosRegistration from './components/datos_registration/registration';
import Asientos from './components/evento_dinamico/asientos'
import Signin from './auth/signin';

import Program from './components/program/program';
import Programedit from './components/programedit/programedit';
import Speakers from './components/speakers/speakers';
import Speakersedit from './components/speakersedit/speakersedit';
import Infop from './components/infop/infop';
import Infopedit from './components/infopedit/infopedit';

import RegistroConvecion from './components/asistentes/registro-convencion'
import DetalleConvencion from './components/asistentes/detalle-convencion'
import Cortesias from './components/asistentes/cortesias'
import Depositos from './components/depositos/registros-depositos'
import DetalleDeposito from './components/depositos/detalle-deposito'

import Asistentes from './components/asistentes/index'
import Imprimir from './components/pruebas/imprimir'

import RegistrosHospedaje from './components/hospedaje/registros_hospedaje'
import DetalleHospedaje from './components/hospedaje/detalle_hospedaje'
import MesaRegistro from './components/mesa_registro/mesa'
import MesaRegistroNuevo from './components/mesa_registro/mesa_form_alta'

import RutaProtegida from './components/RutaProtegida';
import AppLayout from './components/app';
import FormCarnet from './components/mesa_registro/mesa_form_alta';

import { Routes, Route, Navigate } from 'react-router-dom';
import AsientosClausura from './components/evento_dinamico/asientos_clausura';

const NoAutorizado = () => {
  return <Fragment>
    <div>
      <h1>No tiene los permisos para ver este contenido</h1>
      <p>Laborum labore tempor fugiat dolore minim. Est commodo velit dolore eiusmod dolor. Pariatur occaecat nisi sint officia proident qui tempor. Sint commodo excepteur ullamco eiusmod eu do. Proident veniam aute proident dolor ipsum sit incididunt. Elit commodo est mollit laborum aute officia laborum dolore ex occaecat. Culpa occaecat aute nulla voluptate.</p>
    </div>
  </Fragment>
}

const RutasCMS = () => {
  return (
    <AppLayout>
      <Routes>
        {/* ============== RUTAS INTERNAS DEL CMS ============== */}
        <Route element={<RutaProtegida permisos={[1, 2, 7, 8, 9, 12,13, 16,17]} />}>
          <Route path='/detalle-convencion/:evento/:tps/:folio' element={<DetalleConvencion />} />
        </Route>

        <Route element={<RutaProtegida permisos={[1, 2, 7, 8, 9, 12,13, 16,17]} validarRuta={true} />}>
          <Route path='/registros/:evento/:tps' element={<RegistroConvecion />} />
        </Route>

        <Route element={<RutaProtegida permisos={[3]} />}>
          <Route path='/dashboard_graficas' element={<Dashboard />}/>
        </Route>

        <Route element={<RutaProtegida permisos={[4]} />}>
          <Route path='/depositos' element={<Depositos />}/>
          <Route path='/detalle-deposito/:id' element={<DetalleDeposito />}/>
        </Route>

        <Route element={<RutaProtegida permisos={[5]} />}>
          <Route path='/cortesias' element={<Cortesias />}/>
        </Route>

        <Route element={<RutaProtegida permisos={[6]} />}>
          <Route path='/asientos' element={<Asientos />}/>
        </Route>

        <Route element={<RutaProtegida permisos={[6]} />}>
          <Route path='/asientos_clausura' element={<AsientosClausura />}/>
        </Route>

        <Route element={<RutaProtegida permisos={[13]} />}>
          <Route path='/carnet/nuevo' element={<FormCarnet />}/>
        </Route>

        <Route>
          <Route path='/carnet/editar/:id' element={<FormCarnet esEditar={1}/>} />
        </Route>
        
        <Route element={<RutaProtegida permisos={[10, 11, 16]} />}>
          <Route path='/hospedaje' element={<RegistrosHospedaje />}/>
          <Route path='/detalle-hospedaje/:evento/:tps/:folio' element={<DetalleHospedaje />}/>
        </Route>

        {/* AGM */}

        <Route element={<RutaProtegida permisos={[2]} />}>
          <Route path='/dashboard' element={<Dashboard/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[3]} />}>
          <Route path='/president' element={<President/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[4]} />}>
          <Route path='/registration' element={<Registration/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[5]} />}>
          <Route path='/program' element={<Program/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[6]} />}>
          <Route path='/speakers' element={<Speakers/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[7]} />}>
          <Route path='/location' element={<Fragment/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[8]} />}>
          <Route path='/info' element={<Infop/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[9]} />}>
          <Route path='/info-edit/:id' element={<Infopedit/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[9]} />}>
          <Route path='/program-edit/:idDay/:idProgram' element={<Programedit/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[9]} />}>
          <Route path='/speakers-edit/:id' element={<Speakersedit/>}/>
        </Route>

        {/* <Route element={<RutaProtegida permisos={[9]} />}>
          <Route path='/venue' element={<Fragment/>}/>
        </Route> */}

        <Route element={<RutaProtegida permisos={[10]} />}>
          <Route path='/dress-code' element={<Fragment/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[11]} />}>
          <Route path='/awards' element={<Fragment/>}/>
        </Route>

        <Route element={<RutaProtegida permisos={[12]} />}>
          <Route path='/anti-trust-statement' element={<Fragment/>}/>
        </Route>
        {/* ============== RUTA NO EXISTENTE ============== */}
        <Route path="*" element={<Navigate to={'/registros/2/1'} />} />
      </Routes>
    </AppLayout>
  )
}

function App() {
  return (
    <Routes>
      {/* ============== RUTAS PÚBLICAS ============== */}
      <Route path={`${process.env.PUBLIC_URL}/login`} element={<Signin />}/>
      <Route path={`${process.env.PUBLIC_URL}/asistentes`} element={<Asistentes />}/>
      <Route path={`${process.env.PUBLIC_URL}/imprimir`} element={<Imprimir />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro`} element={<MesaRegistro />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro/nuevo`} element={<FormCarnet esMesaRegistro={true} />}/>
      <Route path={`${process.env.PUBLIC_URL}/mesaregistro/editar/:id`} element={<FormCarnet esMesaRegistro={true} esEditar={1} />}/>
      <Route path={`${process.env.PUBLIC_URL}/no-autorizado`} element={<NoAutorizado />}/>
      {/* ============== RUTAS CON PERMISOS ============== */}
      <Route path='*' element={<RutasCMS />} />
    </Routes>
  );
}

export default App;