import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ProgramContent = ({data,setActualizar}) => {
    const [content, setContent] = useState({})
    useEffect(() => {
        setContent(data)
    }, [data]);

    function ocultarMostrarProgram(){
        fetch('/p_program', {
            method: 'SUBSCRIBE',
            body: JSON.stringify({id: ID_PDD}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }

    function eliminarProgram(){
        fetch('/p_program', {
            method: 'DELETE',
            body: JSON.stringify({id: ID_PDD}),
            headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then(res => {
            setActualizar(true);
        })
        .catch(err => console.log('Error: '+err))
    }
    
    const {ID_PDD,ID_PD_PDD,TITLE_PDD,DESCRIPTION_PDD,SPEAKER_PDD,ROOM_VENUE_PDD,START_HOUR_PDD,END_HOUR_PDD,IS_VISIBLE_END_HOUR_PDD,EXTRA_PDD,TEXT_PDD,ID_ICO_PDD,ORDER_PDD,IS_VISIBLE_PDD,START_HOUR,END_HOUR} = content

    return (
        <Fragment>
            <div className="col-md-6 mb-4 cardEvent">
                <h6>{TITLE_PDD}</h6>
                <div className="event">
                    <div className="hora">Hour: {START_HOUR} {IS_VISIBLE_END_HOUR_PDD ? `/ ${END_HOUR}` : ''} Hrs.</div>
                    <div className="eventName">Title: {TITLE_PDD}</div>
                    <div className="roomName">Room: {ROOM_VENUE_PDD}</div>
                    <div className="extra">Extra: {EXTRA_PDD}</div>
                </div>
                <div className="buttonsTicket">
                    <Link to={`/program-edit/${ID_PD_PDD}/${ID_PDD}`}><button className="btnTicket edit"><i className="fa-regular fa-pen-to-square"></i> Edit</button></Link>
                    <button className="btnTicket delete" onClick={eliminarProgram}><i className="fa-regular fa-trash-can"></i> Delete</button>
                    {
                        IS_VISIBLE_PDD ?
                        <button className="btnTicket unhide" onClick={ocultarMostrarProgram}><i className="fa-regular fa-eye"></i> Unhide</button>
                        :<button className="btnTicket hide" onClick={ocultarMostrarProgram}><i className="fa-regular fa-eye-slash"></i> Hide</button>
                    }
                </div>
            </div>
        </Fragment>
    );
}
 
export default ProgramContent;