import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from "../common/breadcrumb";
import TablaPro from "../../componentes/tabla"
import { useNavigate, useParams } from "react-router-dom";

import { formatoDinero } from '../../constant/funcionesGenerales';

const Componente = (props) => {
	
	/* ============== VARIABLES ============== */
	const params = useParams();
	const DEPOSITO = params.id;
	const history = useNavigate()
    let timeout, busqueda_pendiente = '';

	/* ============== HOOKS ============== */
	const [informacionDeposito, setInformacionDeposito] = useState({
		clave: DEPOSITO,
		fecha: '',
		ingreso_bruto: '',
		ingreso_neto: ''
	})
    const [filtros, setFiltros] = useState({
		DEPOSITO,
        BUSQUEDA: ''
    })
	
	/* ============== USE EFFECT ============== */
	useEffect(() => {
		fetch('../rt_informacion_deposito', {
			method: 'POST',
			body: JSON.stringify({DEPOSITO}),
			headers: {'Content-Type': 'application/json'}
		}).then(res => res.json())
		.then(res =>  {
			if(res.status === 'OK'){
				console.log('respuesta info deposito', {fecha: res.result.FECHA, ingreso_bruto: res.result.INGRESO_BRUTO, ingreso_neto: res.result.INGRESO_NETO});
				setInformacionDeposito((s) => ({...s, fecha: res.result.FECHA, ingreso_bruto: res.result.INGRESO_BRUTO, ingreso_neto: res.result.INGRESO_NETO}))
			}
		}).catch(err => {
			console.log(err);
		})
	}, [])
	
	/* ============== FUNCIONES ============== */
	const evaluarEscribiendo = (e) => {
		console.log('Evaluar escribiendo', e.target.value);
		if(timeout){
			clearTimeout(timeout)
		}

		busqueda_pendiente = e.target.value;

		timeout = setTimeout(() => {
			setFiltros((s) => ({...s, BUSQUEDA: busqueda_pendiente}))
		}, 500)
	}
	
	return (
		<Fragment
		>
			<div className="col-sm-12 mt-5">
				<div className="row">
					<div className="col-sm-12 mb-1 mt-3">
						<Breadcrumb
						title="Transacciones de depósito"
						parent="Reporte clip"
						/>
					</div>
				</div>	
				<div className="row">
					<div className="col-12">
					<div
						className="d-flex justify-content-evenly flex-wrap"
						style={{ fontSize: 16 }}
					>

						<div className="info-container mx-4">
							<label className="col-form-label text-left">
								Clave de depósito: &nbsp;
							</label>
							<label className="col-form-label text-left font-weight-bold">
								{DEPOSITO}
							</label>
						</div>

						<div className="info-container mx-4">
							<label className="col-form-label text-left">
								Fecha de depósito: &nbsp;
							</label>
							<label className="col-form-label text-left font-weight-bold">
								{informacionDeposito.fecha}
							</label>
						</div>

						<div className="info-container mx-4">
							<label className="col-form-label text-left">
								Ingreso bruto: &nbsp;
							</label>
							<label className="col-form-label text-left font-weight-bold">
								{'$'+formatoDinero(informacionDeposito.ingreso_bruto)}
							</label>
						</div>

						<div className="info-container mx-4">
							<label className="col-form-label text-left">
								Ingreso neto: &nbsp;
							</label>
							<label className="col-form-label text-left font-weight-bold">
								{'$'+formatoDinero(informacionDeposito.ingreso_neto)}
							</label>
						</div>

					</div>
					</div>
				</div>
				<div className="col-xl-6 mb-2 mt-3">
					<label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
						BUSCAR:
					</label>
					<input
						id="input_buscador"
						type="text"
						className="form-control"
						name="buscar"
						aria-label=""
						aria-describedby="basic-addon1"
						onKeyUp={evaluarEscribiendo}
					/>
				</div>

				<div className='mt-4'>
					<TablaPro
							cargando={false}
							headers = {[
								{nombre: 'Folio/producto', tipo: 'normal', titulo: 'FOLIO', texto: 'PRODUCTO'},
								{nombre: 'Nombre/correo', tipo: 'normal', titulo: 'NOMBRE', texto: 'CORREO'},
								{nombre: 'Recibo/referencia', tipo: 'normal', titulo: 'RECIBO', texto: 'REFERENCIA'},
								{nombre: 'Total pagado', tipo: 'individual', texto: 'TOTAL', formato: 'dinero'},

								{nombre: 'Detalles', tipo: 'acciones', acciones: [
									{texto: 'Ver', onClick: (e) => {history(`/detalle-convencion/${e.ID_E}/${e.ID_T}/${e.FOLIO}`);}, params: ['FOLIO', 'ID_E', 'ID_T']},
								]
								}
							]}
							totalField={'NR'} /* NR = número de resultados totales */
							path={'../rt_transacciones_deposito'}
							body={filtros}
					></TablaPro>
				</div>
			</div>
		</Fragment>
	)
}


export default Componente;