import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { useNavigate } from "react-router-dom";
import notie from 'notie';

import TablaPro from "../../componentes/tabla"

const RegistroHospedaje = () => {

    /* ============== VARIABLES ============== */
    const history = useNavigate();
    let timeout, busqueda_pendiente = '';


    /* ============== HOOKS ============== */
    const [filtros, setFiltros] = useState({
        BUSQUEDA: '',
        HOTEL: '',
    });

    const [catalogos, setCatalogos] = useState({
        hoteles: []
    });

    /* ============== USE EFFECT ============== */
    useEffect(() => {
        fetch('./rt_obtener_catalogos_hospedaje', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
        }).then(res => res.json())
        .then(res => {
            console.log(res);
            if(res.estatus == 'OK') {
                setCatalogos(s => ({...s, hoteles: res.datos}))
            } else {
                throw Error(res.estatus);
            }
        }).catch(err => {
            console.log({err});
            notie.alert({text: 'No se pudo obtener la información de los hoteles', type: 4, time: 6});
        });
    }, [])


    /* ============== FUNCIONES ============== */
    const evaluarEscribiendo = (e) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        busqueda_pendiente = e.target.value;

        timeout = setTimeout(() => {
            setFiltros((s) => ({ ...s, BUSQUEDA: busqueda_pendiente }))
        }, 500)
    }

    const excelRegistros = (e) => {
        fetch('/rt_excel_hospedaje', {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ datos: filtros })
        })
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (res) {
                if (res) {
                    let blob = new Blob([res], { type: "application/xlsx" });
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    let fileName = 'hospedaje.xlsx';
                    link.download = fileName;
                    link.click();
                }
            }).catch(function (err) {
                console.log('Error al cargar registros:', err);
            })
    }

    return (
        <Fragment>
            <div className="col-sm-12 mt-5">
                <div className="row">
                    <div className="col-sm-12 mb-2 mt-3">
                        <Breadcrumb title="Registros Hospedaje" parent="Inicio" />
                    </div>
                    <div className="col-xl-6 mb-2 mt-3">
                        <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                            BUSCAR:
                        </label>
                        <input
                            id="input_buscador"
                            type="text"
                            className="form-control"
                            name="buscar"
                            aria-label=""
                            aria-describedby="basic-addon1"
                            onKeyUp={evaluarEscribiendo}
                        />
                    </div>
                    <div className="col-md-3 col-sm-4 col-12 d-flex mb-sm-2 mb-3 justify-content-start align-items-end">
                        <button type="button" className="btn btn-success" onClick={() => { }}>
                            <i className="fa fa-refresh"></i> Limpiar Filtro
                        </button>
                        <select className='select-estatus' style={{maxWidth: 180}} onClick={(e) => {
                            let val = e.target.value;
                            if(val != filtros.HOTEL)
                                setFiltros((s) => ({...s, HOTEL: val}));
                        }}>
                            <option value=''>Todos</option>
                            {
                                catalogos.hoteles.map((hotel) => {
                                    return <option key={`option_hoteles_${hotel.ID}`} value={hotel.ID}>{hotel.NOMBRE}</option>
                                })
                            }
                        </select>
                        <button type="button" className="btn btn-success mt-2 mt-sm-0 ml-2 ml-sm-2" onClick={() => { excelRegistros() }}>
                            <i className="fa fa-download"></i> Descargar reporte
                        </button>
                    </div>
                </div>

                {/* ============== AGREGAR ELEMENTO TABLAPRO ============== */}

                <TablaPro
                    cargando={false}

                    /* ID, FOLIO, NOMBRE, TELEFONO, CORREO, RFC, ID_EVE, ID_TPS */
                    headers={[
                        { nombre: 'Clave', tipo: 'individual', texto: 'FOLIO' },
                        { nombre: 'Comercio', tipo: 'normal', titulo: 'NOMBRE', texto: 'CORREO' },
                        { nombre: 'Teléfono', tipo: 'individual', texto: 'TELEFONO' },
                        { nombre: 'RFC', tipo: 'individual', texto: 'RFC' },
                        { nombre: 'Hotel', tipo: 'individual', texto: 'HOTEL' },
                        // { nombre: 'Estatus', tipo: 'individual', formatear: (r) => r.PENDIENTES > 0 ? 'Pendiente' : 'Enviado' },
                        {
                            nombre: 'Detalles', tipo: 'acciones', acciones: [
                                {
                                    texto: 'Ver', onClick: (e) => {
                                        console.log('redirect a', `/detalle-hospedaje/${e.ID_EVE}/${e.ID_TPS}/${e.FOLIO}`);
                                        history(`/detalle-hospedaje/${e.ID_EVE}/${e.ID_TPS}/${e.FOLIO}`);
                                    }, params: ['FOLIO', 'ID_EVE', 'ID_TPS']
                                },
                            ]
                        }
                    ]}
                    totalField={'NR'} /* NR = número de resultados totales */
                    path={'./rt_registros_hospedaje'}
                    body={filtros}
                ></TablaPro>

                {/* ============== AGREGAR ELEMENTO TABLAPRO ============== */}

            </div>
        </Fragment>
    )
}

export default RegistroHospedaje;