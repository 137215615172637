import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../common/breadcrumb";
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from "reactstrap";
import { Accordion, AccordionItem } from "react-light-accordion";
import { useParams } from 'react-router-dom';
import { groupArrayByKey } from "../../constant/funcionesGenerales";
import notie from 'notie'

const Componente = () => {
	
	/* ============== VARIABLES ============== */
	const params = useParams();

	console.log('params', params);
	/* ============== HOOKS ============== */
	const [registro, setRegistro] = useState({
		estatus: [],
		informacion: {},
		subcarnets: [],
		asistentes: [],
		miembro: 0,
		ruta: ''
	})

	const [ddState, setDdState] = useState({
		dropdownOpen: false,
		isOpen: false,
		collapse: false,
	})

	const [idEditando, setIdEditando] = useState();
	const [editando, setEditando] = useState(false);
	
	/* ============== USE EFFECT ============== */
	useEffect(() => {
		fetch(`../../../rt_registros_hospedaje/detalle`, {
			method: 'POST',
			body: JSON.stringify({ evento: params.evento, tps: params.tps, folio: params.folio}),
			headers: {'Content-Type': 'application/json'}
		}).then(function(res) { return res.json(); })
		.then(function(res) {
			console.log('Información:', res);
			if(res.status == 'OK'){
				let subcarnets = groupArrayByKey(res.asistentes, 'ID_SUBCARNET', 'SUBCARNET');
				subcarnets = Object.values(subcarnets);

				res.result.codigo_descuento = res.codigo_descuento;
				setRegistro((s) => ({...s, informacion: res.result, subcarnets, asistentes: res.asistentes, estatus: res.estatus, miembro: res.miembro.ID, ruta: res.ruta.RUTA}))
			}
			abrirAccordion();
		}).catch(function(err){
			console.log('Error al cargar catálogos:', err);
		});
	}, []);
	
	/* ============== FUNCIONES ============== */
	const toggleDrop = () => {
		setDdState((s) => ({...s, dropdownOpen: !ddState.dropdownOpen}));
	}

	const clickDropdown = (e) => {
		console.log('clickDropdown e:', e);
	}

	const abrirAccordion = () => { 
		let accordion_items = document.querySelectorAll('.accordion-item>.title')
		for(let item of accordion_items){
			item.click();
		}
	}

	const validarEnviarCodigoReservacion = (id, guardar) => {
		notie.confirm({
			text: 'Se enviará el código de reservación a el/los asistentes, ¿desea continuar?',
			submitText: 'Enviar',
			cancelText: 'Cancelar',
			position: 'top',
			submitCallback: () => {
				if(guardar){
					setEditando(false);
					setIdEditando(null);
				}
				enviarCodigoReservacion(id);
			}
		})
	}

	const enviarCodigoReservacion = (id) => {
		fetch(`../../../rt_registros_hospedaje/enviar_confirmacion_reservacion`, {
				method: 'POST',
				body: JSON.stringify({
					id,
					tps:    params.tps,
					folio:  params.folio,
					evento: params.evento,
					codigo: document.getElementById(`input_subcarnet_${id}`).value,
				}),
				headers: {'Content-Type': 'application/json'}
		}).then(res => res.json())
		.then(res =>  {
			console.log('res', res);
			if(res.status == 'OK'){
				notie.alert({text: 'Se envió el código de reservación', type: 1, time: 3});
			}else{
				throw Error(res.status);
			}			
		}).catch(err => {
				notie.alert({text: 'No se pudo enviar el código, inténtelo más tarde', type: 3, time: 3});
		})
	}
	
	const finalizarEditando = () => {
		validarEnviarCodigoReservacion(idEditando, true);		
	}

	return (
		<Fragment>
		<div className="col-sm-12 mb-1 mt-3">
		<Breadcrumb
			title="Información de hospedaje"
			parent="Registros Hospedaje"
		/>
		</div>

		<div className="row">
		<div className="col-12">
			<div
			className="d-flex justify-content-evenly flex-wrap"
			style={{ fontSize: 16 }}
			>
			<div className="info-container mx-4">
				<label className="col-form-label text-left">
				ID de Registro: &nbsp;
				</label>
				<label className="col-form-label text-left font-weight-bold">
				{registro.informacion.FOLIO || '-'}
				</label>
			</div>
			<div className="info-container mx-4">
				<label className="col-form-label text-left">
				Fecha Registro: &nbsp;
				</label>
				<label className="col-form-label text-left font-weight-bold">
				{registro.informacion.FECHA_ALTA || '-'}
				</label>
			</div>
			<div className="info-container mx-4">
				<label className="col-form-label text-left">
				Fecha de Pago: &nbsp;
				</label>
				<label className="col-form-label text-left font-weight-bold">
				{registro.informacion.FECHA_PAGO || '-'}
				</label>
			</div>
			<div className="info-container mx-4">
				<label className="col-form-label text-left">
				Confirmación de Pago: &nbsp;
				</label>
				<label className="col-form-label text-left font-weight-bold">
				{registro.informacion.FECHA_FIN || '-'}
				</label>
			</div>
			</div>
		</div>
		</div>

			<div
				className="default-according panel-accordion"
				id="accordionclose"
			>
				<Accordion atomic={false}>
					<AccordionItem 
						className="card-header bg-primary"
						title={
							<div className="card-title-detalle">
								<i className="fa fa-user-circle-o"></i>
								<h5 className="ml-3 font-weight-bold mb-0">
									Datos personales
								</h5>
							</div>
						}
					>
						<div
							className="collapse show"
							id="collapseicon1"
							aria-labelledby="collapseicon1"
							data-parent="#accordionoc"
						>
							<div className="card container-fluid">
								<br />
								<div className="row m-auto">
									<div className="col-md-12 p-0">
										<form className="theme-form">
											<div className="row m-auto">
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Nombre(s): &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.NOMBRES || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Apellidos: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.APELLIDOS || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Correo electrónico: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.CORREO || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Teléfono: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.CELULAR ? `+${registro.informacion.CELULAR_CODIGO} ${registro.informacion.CELULAR}` : '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Whatsapp: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.WHATSAPP ? `+${registro.informacion.WHATSAPP_CODIGO} ${registro.informacion.WHATSAPP}` : '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Tipo de Asistente: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.TIPO_ASISTENTE || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Grupo Gasolinero: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.GRUPO_GASOLINERO || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Estación de Servicio: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.ESTACION_SERVICIO || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Estado: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.ESTADO || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Ciudad: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.CIUDAD || '-'}
														</label>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
								<br />
							</div>
						</div>
					</AccordionItem>
					<AccordionItem  
						className="card-header bg-primary"
						title={
							<div className="card-title-detalle">
								<i className="fa fa-folder-open-o"></i>
								<h5 className="ml-3 font-weight-bold mb-0">
									Datos de facturación
								</h5>
							</div>
						}
					>
						<div
							className="collapse show"
							id="collapseicon"
							aria-labelledby="collapseicon"
							data-parent="#accordionoc"
						>
							<div className="card container-fluid">
								<br />
								<div className="row m-auto">
									<div className="col-md-12 p-0">
										<form className="theme-form">
											<div className="row m-auto">
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Razón Social: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.RAZON_SOCIAL || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Régimen Fiscal: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.REGIMEN_FISCAL || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															RFC: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.RFC || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Método de Pago: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.FORMA_PAGO || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Uso de CFDI: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.USO_CFDI || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Calle: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.CALLE || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Colonia: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.COLONIA || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Núm. Exterior: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.NUM_EXT || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Núm. Interior: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.NUM_INT || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Código Postal: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.CODIGO_POSTAL || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Estado: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.ESTADO_FISCAL || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Ciudad: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.CIUDAD_FISCAL || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Correo electrónico: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.CORREO_FISCAL || '-'}
														</label>
													</div>
												</div>
												<div className="col-md-6">
													<div className="info-container">
														<label className="col-form-label text-left font-weight-bold">
															Teléfono: &nbsp;
														</label>
														<label className="col-form-label text-left">
															{registro.informacion.TELEFONO ? `+${registro.informacion.TELEFONO_CODIGO} ${registro.informacion.TELEFONO}` : '-'}
														</label>
													</div>
												</div>
												<div className="col-md-12">
													<label className="col-form-label text-left font-weight-bold">
														Comentarios: &nbsp;
													</label>
													<label className="col-form-label text-left alert-danger">
														{registro.informacion.COMENTARIOS || '-'}
													</label>
												</div>
											</div>
										</form>
									</div>
								</div>
								<br />
							</div>
						</div>
					</AccordionItem>
					<AccordionItem  
						className="card-header bg-primary"
						title={
							<div className="card-title-detalle">
								<i className="fa fa-user-o"></i>
								<h5 className="ml-3 font-weight-bold mb-0">Asistentes</h5>
							</div>
						}
					>
						<div
							className="collapse show"
							id="collapseicon3"
							aria-labelledby="collapseicon3"
							data-parent="#accordionoc"
						>
							<div className="card container-fluid">
								<br />
								<div className="row m-auto w-100">
									<div className="col-md-12 p-0">
										<form className="theme-form">
											<div className="row m-auto">

											{
												registro.subcarnets.map((subcarnet, i) => 
												<div className="col-md-6 mb-3" key={`subcarnet${i}`}>
													<h5> <strong>Tipo de carnet:&nbsp;</strong>{subcarnet.key}</h5>
													<strong>Tipo de habitación:&nbsp;</strong>{`${subcarnet.array[0].CAMAS} ${subcarnet.array[0].CAMAS === 1 ? 'CAMA': 'CAMAS'}`}
													{
													<Fragment>
														<div className="row" style={{height: 15}}></div>                                
														<div className="row">
															<div className="col-12">
																<div className="info-container">
																	<label className="col-form-label text-left font-weight-bold">
																		Hotel: &nbsp;
																	</label>
																	<label className="col-form-label text-left">
																		{subcarnet.array[0] ? `${subcarnet.array[0].HOTEL}` : '-'}
																	</label>
																</div>
															</div>
															<div className="col-12">
																<div className="row">
																	<div className="col-6">
																		<div className="info-container">
																			<label className="col-form-label text-left font-weight-bold">
																				Fecha entrada: &nbsp;
																			</label>
																			<label className="col-form-label text-left">
																				{subcarnet.array[0] && subcarnet.array[0].CHECKIN ? `${subcarnet.array[0].CHECKIN}` : '-'}
																			</label>
																		</div>
																	</div>
																	<div className="col-6">
																		<div className="info-container">
																			<label className="col-form-label text-left font-weight-bold">
																				Fecha salida: &nbsp;
																			</label>
																			<label className="col-form-label text-left">
																				{subcarnet.array[0] && subcarnet.array[0].CHECKOUT ? `${subcarnet.array[0].CHECKOUT}` : '-'}
																			</label>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-12 d-none" style={{height: 8}}></div>
															<div className="col-12 col-sm-8 d-none">
																<input id={`input_subcarnet_${subcarnet.array[0].ID_SUBCARNET}`} disabled={subcarnet.array[0].ESTATUS_RESERVACION === 1 ? editando && idEditando === subcarnet.array[0].ID_SUBCARNET ? false: true : false} className="form-control" type="text" placeholder="Código de reservación" defaultValue={subcarnet.array[0].CODIGO_RESERVACION} />
															</div>
															<div className="col-12 col-sm-4 d-none">
																<div className="row text-left justify-content-around">
																	{
																		subcarnet.array[0].ESTATUS_RESERVACION === 1 ?
																		<Fragment>
																			<button type="button" onClick={() => {validarEnviarCodigoReservacion(subcarnet.array[0].ID_SUBCARNET)}} className="btn btn-warning">Reenviar</button>
																			{editando && idEditando === subcarnet.array[0].ID_SUBCARNET ?
																				<button type="button" onClick={() => {
																					finalizarEditando();
																				}} className="btn btn-success">Guardar</button>
																				:
																				<button type="button" onClick={() => {
																					setEditando(true);
																					setIdEditando(subcarnet.array[0].ID_SUBCARNET);
																				}} className="btn btn-success">Editar</button>
																			}
																		</Fragment>
																		:
																		<button type="button" onClick={() => {validarEnviarCodigoReservacion(subcarnet.array[0].ID_SUBCARNET)}} className="btn btn-success">Enviar</button>
																	}
																</div>
															</div>
														</div>
														{
														subcarnet.array.map((asistente) => 
														<div className="row">
															<div className="col-12" style={{height: 15}}></div>
															<div className="col-12">
																<div className="info-container">
																	<label className="col-form-label text-left font-weight-bold">
																		Nombre Asistente: &nbsp;
																	</label>
																	<label className="col-form-label text-left">
																		{asistente.NOMBRES ? `${asistente.NOMBRES} ${asistente.APELLIDOS}` : ''}
																	</label>
																</div>
															</div>
															<div className="col-12">
																<div className="info-container">
																	<label className="col-form-label text-left font-weight-bold">
																		Correo Electrónico: &nbsp;
																	</label>
																	<label className="col-form-label text-left">
																		{asistente.CORREO || ''}
																	</label>
																</div>
															</div>
															<div className="col-12">
																<div className="info-container">
																	<label className="col-form-label text-left font-weight-bold">
																		WhatsApp: &nbsp;
																	</label>
																	<label className="col-form-label text-left">
																		{asistente.WHATSAPP ? `+${asistente.WHATSAPP_CODIGO} ${asistente.WHATSAPP}` : ''}
																	</label>
																</div>
															</div>
														</div>
														)
														}
													</Fragment>
													}
												</div>
												)
											}
											</div>
										</form>
									</div>
								</div>
								<br />
							</div>
						</div>
					</AccordionItem>					
				</Accordion>
			</div>
				<div style={{height: 50}}></div>
		</Fragment>
	)
}


export default Componente;