import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import SesionContexto from "../context/ContextoSesion";

const RutaProtegida = ({permisos, validarRuta}) => {

    const location = useLocation();

    const {sesion} = useContext(SesionContexto);


    // Se valida que el permiso requerido exista en los permisos d la sesión
    // Si se solicita validar la ruta (validarRuta = true), además de verificar que exista el permiso en la sesión, se valida que la ruta actual sea igual a la del permiso
    
    return (
        (!validarRuta && sesion?.permisos?.find(permiso => permisos.includes(permiso.ID))) 
        ||
        ( validarRuta && sesion?.permisos?.find(permiso => permisos.includes(permiso.ID) && location.pathname === permiso.RUTA)) ?  
          <Outlet /> // Autorizado
        : sesion?.correo ? // Se valida si existe una sesión
          <Navigate to={sesion?.permisos[0]?.RUTA || '/'} />
        : <Navigate to='/login' />
    )
};

export default RutaProtegida;