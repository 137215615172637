import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import Breadcrumb from "../common/breadcrumb";
import { useNavigate } from "react-router-dom";

import TablaPro from "../../componentes/tabla"

const Componente = () => {
    
    /* ============== VARIABLES ============== */
	const history = useNavigate();
    let timeout, busqueda_pendiente = '';
    

    /* ============== HOOKS ============== */
    const [informacionDepositos, setInformacionDepositos] = useState([]);
    const [filtros, setFiltros] = useState({
        BUSQUEDA: ''
    })
    
    /* ============== USE EFFECT ============== */
    
    /* ============== FUNCIONES ============== */
	const evaluarEscribiendo = (e) => {
		console.log('Evaluar escribiendo', e.target.value);
		if(timeout){
			clearTimeout(timeout)
		}

		busqueda_pendiente = e.target.value;

		timeout = setTimeout(() => {
			setFiltros((s) => ({...s, BUSQUEDA: busqueda_pendiente}))
		}, 500)
	}

    return (
		<Fragment>
			<div className="col-sm-12 mt-5">
				<div className="row">
					<div className="col-sm-12 mb-2 mt-3">
						<Breadcrumb title="Reporte clip" parent="Inicio" />
					</div>
					<div className="col-xl-6 mb-2 mt-3">
						<label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
							BUSCAR:
						</label>
						<input
							id="input_buscador"
							type="text"
							className="form-control"
							name="buscar"
							aria-label=""
							aria-describedby="basic-addon1"
							onKeyUp={evaluarEscribiendo}
						/>
					</div>
					<div className="col-md-3 col-sm-4 col-12 d-flex mb-sm-2 mb-3 justify-content-start align-items-end">
						<button type="button" className="btn btn-success" onClick={() => {}}>
							<i className="fa fa-refresh"></i> Limpiar Filtro
						</button>
					</div>
				</div>

                {/* ============== AGREGAR ELEMENTO TABLAPRO ============== */}

                <TablaPro
                    cargando={false}
                    headers = {[
                        {nombre: 'Clave', tipo: 'individual', texto: 'CLAVE'},
                        {nombre: 'Comercio', tipo: 'individual', texto: 'COMERCIO'},
                        {nombre: 'Fecha', tipo: 'individual', texto: 'FECHA'},
                        {nombre: 'Ingreso bruto', tipo: 'individual', texto: 'INGRESO_BRUTO', formato:"dinero"},
                        {nombre: 'Monto cuota', tipo: 'individual', texto: 'TOTAL_CUOTA', formato:"dinero"},
                        {nombre: 'Monto impuesto', tipo: 'individual', texto: 'TOTAL_IMPUESTO', formato:"dinero"},
                        {nombre: 'Monto retenido', tipo: 'individual', texto: 'TOTAL_RETENIDO', formato:"dinero"},
                        {nombre: 'Ingreso neto', tipo: 'individual', texto: 'INGRESO_NETO', formato:"dinero"},
                        {nombre: 'Transacciones', tipo: 'individual', texto: 'CANTIDAD_TRANSACCIONES'},

                        {nombre: 'Detalles', tipo: 'acciones', acciones: [
                            {texto: 'Ver', onClick: (e) => {
                                history(`/detalle-deposito/${e.CLAVE}`);
                            }, params: ['CLAVE']},
                        ]
                        }
                    ]}
                    totalField={'NR'} /* NR = número de resultados totales */
                    path={'./rt_registros_depositos'}
                    body={filtros}
                ></TablaPro>

                {/* ============== AGREGAR ELEMENTO TABLAPRO ============== */}

			</div>
		</Fragment>
    )
}


export default Componente;