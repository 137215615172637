import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const Paginador = ({pagina, paginas, ultima_pagina, cargarDatos}) => {
	return <Pagination aria-label="Page navigation" className="pagination-primary new-pagination">
		
		<PaginationItem onClick={() => {if(pagina > 1) cargarDatos(1);}} disabled={pagina === 1 ? true: false}>
			<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-double-left"></i>
			</PaginationLink>
		</PaginationItem>
		
		<PaginationItem onClick={() => {if(pagina > 1) cargarDatos(pagina-1);}} disabled={pagina === 1 ? true: false}>
			<PaginationLink  className="bg-blue arrow">
				<i className="fa fa-angle-left"></i>
			</PaginationLink>
		</PaginationItem>

		{
			paginas.map((pag, i) => {
				return <PaginationItem key={`PaginationItem${i}`} onClick={() => {cargarDatos(pag);}} className={pag == pagina ? "active": ""}>
			<PaginationLink >{pag}</PaginationLink>
		</PaginationItem>
			})
		}

		<PaginationItem onClick={() => {if(pagina < ultima_pagina) cargarDatos(pagina + 1);}} disabled={pagina == ultima_pagina ? true: false}>
			<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-right"></i>
			</PaginationLink>
		</PaginationItem>
		
		<PaginationItem onClick={() => {if(pagina < ultima_pagina) cargarDatos(ultima_pagina);}} disabled={pagina == ultima_pagina ? true: false}>
			<PaginationLink className="bg-blue arrow">
				<i className="fa fa-angle-double-right"></i>
			</PaginationLink>
		</PaginationItem>

	</Pagination>
}

export default Paginador;