import React, { Fragment, useState, useEffect, Component } from 'react';
import Breadcrumb from '../common/breadcrumb';
import Charts from './home-component';
const primary = localStorage.getItem('primary_color') || "#4466f2";
const secondary = localStorage.getItem('secondary_color') || "#1ea6ec";


const Dashboard = () => {
    const [asistentesRegistrados, setAsistentesRegistrados] = useState(0)
    const [asistentesPagados, setAsistentesPagados] = useState(0)
    const [asistentesSinPagar, setAsistentesSinPagar] = useState(0)
    const [hombres, setHombres] = useState(0)
    const [mujeres, setMujeres] = useState(0)
    const [cortesia, setCortesia] = useState(0)
    const [carnetPago, setCarnetPago] = useState({})
    const [tablaEstados, setTablaEstados] = useState([])
    const [estados, setEstados] = useState([])

    const getData = async () => {
        let response = await fetch('/rt_datos_dashboard', {
            method: 'POST',
            body: JSON.stringify({}),
            headers: { 'Content-Type': 'application/json' }
        });
        try {
            let res = await response.json()
            setAsistentesRegistrados(res.asistentesRegistrados)
            setAsistentesPagados(res.asistentesPagados)
            setAsistentesSinPagar(res.asistentesSinPagar)
            setHombres(res.hombres)
            setMujeres(res.mujeres)
            setCortesia(res.cortesia)
            setEstados(res.estados)
            setCarnetPago(res.carnetsPago)
            setTablaEstados(res.tablaEstados)
        }
        catch {

        }
    }
    useEffect(() => {
        getData();
    }, []);

    return (
        <Fragment>
            <Charts
                registradosAsistentes={asistentesRegistrados}
                pagadosAsistentes={asistentesPagados}
                sinPagarAsistentes={asistentesSinPagar}
                hombres={hombres}
                mujeres={mujeres}
                cortesia={cortesia}
                estados={estados}
                carnetsPago={carnetPago}
                tablaEstados={tablaEstados}
            />
        </Fragment>
    );
}

export default Dashboard;